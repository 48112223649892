import React, { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button } from '@mui/material';

const faqs = [
  {
    question: "What is the purpose of this personality-based travel recommendation service?",
    answer:
      "SoulCompass analyzes your unique personality traits to provide tailored travel recommendations that align with your preferences, ensuring a more fulfilling travel experience.",
  },
  {
    question: "How does the personality assessment work?",
    answer:
      "You will complete a comprehensive personality quiz. Based on your responses, SoulCompass generates a detailed report highlighting your traits and suggests travel plans that suit you best.",
  },
  {
    question: "Is my personal information secure?",
    answer:
      "Absolutely. At SoulCompass, we prioritize your privacy and ensure that all personal data is encrypted and used solely for providing personalized recommendations.",
  },
  {
    question: "What do I receive after completing the assessment?",
    answer:
      "Upon completion, you'll receive a detailed personality report along with customized travel itineraries and destination suggestions tailored to your unique profile.",
  },
  {
    question: "Is there a cost associated with this service?",
    answer:
      "Yes, there is a one-time payment for the comprehensive personality analysis and tailored travel recommendations. This ensures lifetime access to your personalized report.",
  },
  {
    question: "Can I retake the assessment if my preferences change?",
    answer:
      "Yes, you can retake the assessment at any time to receive updated recommendations that reflect your current preferences.",
  },
  {
    question: "How accurate are the travel recommendations?",
    answer:
      "SoulCompass recommendations are based on well-researched personality assessment methodologies and are designed to closely match your unique traits and preferences.",
  },
  {
    question: "How do I get started?",
    answer:
      "Simply sign up on SoulCompass, complete the personality quiz, and receive your personalized travel recommendations instantly.",
  },
];

const FAQ = () => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      width: '100%',
      px: { xs: 0, sm: '2rem' }
    }}>
      {/* Top Section */}
      <Box sx={{
        maxWidth: 1140,
        width: '100%',
        backgroundColor: '#ffe8d6',
        padding: '2.5rem 3rem',
        borderRadius: { xs: 0, sm: '16px' },
        textAlign: 'center',
        marginTop: '0',
        mx: 'auto',
        '@media (max-width:599px)': {
          marginTop: 0, // This is the space above
        }
      }}>
        <Typography variant="h1" sx={{
          fontSize: '3rem',
          fontWeight: 'bold',
          color: '#212529',
          mb: 2
        }}>
          Frequently Asked Questions
        </Typography>
        <Typography sx={{
          fontSize: '1.2rem',
          color: '#495057',
          lineHeight: 1.6
        }}>
          Explore how SoulCompass can help you uncover your perfect travel plans.
        </Typography>
      </Box>

      {/* FAQ Content */}
      <Box sx={{
        maxWidth: 800,
        width: '100%',
        margin: '2rem auto',
        px: 2
      }}>
        {faqs.map((faq, index) => (
          <Accordion 
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
              mb: 1,
              borderBottom: '1px solid #dee2e6',
              '&:before': { display: 'none' },
              boxShadow: 'none'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#212529' }} />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  justifyContent: 'space-between',
                  alignItems: 'center'
                },
                padding: 0
              }}
            >
              <Typography sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: '#212529',
                margin: 0
              }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Typography sx={{
                fontSize: '1rem',
                color: '#495057',
                lineHeight: 1.6,
                py: 2
              }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {/* Contact Section */}
      <Box sx={{
        width: '100%',
        maxWidth: 800,
        textAlign: 'left',
        mt: 2,
        mb: 3,
        px: 2
      }}>
        <Typography sx={{
          fontSize: '1rem',
          color: '#495057',
          lineHeight: 1.6,
          mb: 1
        }}>
          Have another question? At SoulCompass, your experience and satisfaction are our highest priorities.
        </Typography>
        <Typography
          component="a"
          href="/contact-us"
          sx={{
            fontSize: '1rem',
            color: 'var(--travel-color)',
            textDecoration: 'underline',
            cursor: 'pointer',
            '&:hover': {
              color: 'darken(var(--travel-color), 10%)'
            }
          }}
        >
          contact us
        </Typography>
      </Box>
    </Box>
  );
};

export default FAQ;