import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import API_BASE_URL from "../config/apiConfig";
import { toast } from "react-toastify";
import "./reset-password.css";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);
    try {
      const res = await fetch(`${API_BASE_URL}/reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, new_password: newPassword }),
      });
      const data = await res.json();

      if (res.ok) {
        toast.success("Password updated!");
        setSuccess(true);
        setTimeout(() => navigate("/signin"), 2000);
      } else {
        setError(data.message || "Reset failed.");
        toast.error(data.message || "Reset failed.");
      }
    } catch (err) {
      setError("Something went wrong.");
      toast.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        <h2>Reset Your Password</h2>
        <p>Enter a new password to secure your account.</p>
        <form onSubmit={handleReset} className="reset-password-form">
          <input
            type="password"
            required
            placeholder="New password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button type="submit" disabled={loading}>
            {loading ? "Updating..." : "Update Password"}
          </button>
          {success && <p className="success-text">✔ Password reset successful.</p>}
          {error && <p className="error-text">⚠️ {error}</p>}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
