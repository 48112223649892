// Header.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../components/LogoSVG.svg';
import HeaderDynamicResult from './HeaderDynamicResult';

/* ===============================================
   1) Minimal Test Header (logo & brand only)
   =============================================== */
function MinimalTestHeader() {
  // On test pages, the logo+brand is non-clickable (rendered as a div).
  return (
    <nav className="navbar navbar-expand-xl navbar-light custom-header">
      <div className="container header-container">
        <div className="navbar-brand fw-bold text-travel d-flex align-items-center">
          <img src={logo} alt="SoulCompass Logo" className="brand-logo" />
          <div className="brand-text d-flex flex-column align-items-start">
            <span>
              SoulCompass<span className="domain">.net</span>™
            </span>
            <small className="text-muted">created by experts</small>
          </div>
        </div>
      </div>
    </nav>
  );
}

/* ===============================================
   2) Full Nav Header (the "old normal" nav)
   =============================================== */
function FullNavHeader({ isAuthenticated, onLogout, userName }) {
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Sync isNavOpen state with the actual collapse element using Bootstrap events.
  useEffect(() => {
    const navBarCollapse = document.getElementById('navbarNav');
    if (!navBarCollapse) return;

    const handleShown = () => setIsNavOpen(true);
    const handleHidden = () => setIsNavOpen(false);

    navBarCollapse.addEventListener('shown.bs.collapse', handleShown);
    navBarCollapse.addEventListener('hidden.bs.collapse', handleHidden);

    return () => {
      navBarCollapse.removeEventListener('shown.bs.collapse', handleShown);
      navBarCollapse.removeEventListener('hidden.bs.collapse', handleHidden);
    };
  }, []);

  // Prevent body scrolling when mobile menu is open
  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };
  }, [isNavOpen]);

  // Close the mobile menu (and update state) when a link is clicked.
  const handleNavLinkClick = () => {
    const navBarCollapse = document.getElementById('navbarNav');
    if (navBarCollapse && navBarCollapse.classList.contains('show')) {
      const bsCollapse = window.bootstrap?.Collapse.getInstance(navBarCollapse);
      if (bsCollapse) {
        bsCollapse.hide();
      } else {
        navBarCollapse.classList.remove('show');
      }
      setIsNavOpen(false);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-header">
      <div className="container header-container">
        {/* Clickable Logo Section (for non‑test pages) */}
        <Link
          className="navbar-brand fw-bold text-travel d-flex align-items-center"
          to="/"
          onClick={handleNavLinkClick}
        >
          <img src={logo} alt="SoulCompass Logo" className="brand-logo" />
          <div className="brand-text d-flex flex-column align-items-start">
            <span>
              SoulCompass<span className="domain">.net</span>™
            </span>
            <small className="text-muted">created by experts</small>
          </div>
        </Link>

        {/* Hamburger Toggler */}
        <button
          className={`navbar-toggler ms-auto ${isNavOpen ? '' : 'collapsed'}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={isNavOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* Collapsible Menu */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto text-center">
            <li className="nav-item">
              <Link
                onClick={handleNavLinkClick}
                className={`nav-link nav-link-hover ${location.pathname === '/' ? 'active' : ''}`}
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={handleNavLinkClick}
                className={`nav-link nav-link-hover ${location.pathname === '/about' ? 'active' : ''}`}
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={handleNavLinkClick}
                className={`nav-link nav-link-hover ${location.pathname === '/pricing' ? 'active' : ''}`}
                to="/pricing"
              >
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={handleNavLinkClick}
                className={`nav-link nav-link-hover ${location.pathname === '/faqs' ? 'active' : ''}`}
                to="/faqs"
              >
                FAQs
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={handleNavLinkClick}
                className={`nav-link nav-link-hover ${location.pathname === '/contact-us' ? 'active' : ''}`}
                to="/contact-us"
              >
                Contact Us
              </Link>
            </li>

            {/* Mobile-only Sign In Button */}
            {!isAuthenticated && (
              <li className="nav-item d-lg-none mt-3">
                <Link
                  onClick={handleNavLinkClick}
                  to="/signin"
                  className="btn btn-outline-travel"
                  style={{
                    width: '80%',
                    maxWidth: '80%',
                    margin: '0 auto',
                    height: '50px',
                  }}
                >
                  Sign In
                </Link>
              </li>
            )}
          </ul>

          {/* Right‐Side (Desktop Only) */}
          <div className="d-none d-lg-flex align-items-center">
            {isAuthenticated && (
              <Link
                to="/test/start"
                className="btn btn-travel btn-equal-size btn-larger me-3"
              >
                Start Your Discovery
              </Link>
            )}

            {isAuthenticated ? (
              <div className="dropdown">
                <button
                  className="btn btn-outline-travel dropdown-toggle d-flex align-items-center"
                  type="button"
                  id="userDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Welcome, {userName || 'User'}
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      My Profile
                    </Link>
                  </li>
                  {/* 
                  <li>
                    <Link className="dropdown-item" to="/Dashboard">
                      My Dashboard
                    </Link>
                  </li>
                  */}
                  <li>
                    <button className="dropdown-item" onClick={onLogout}>
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <Link
                  to="/signin"
                  className="btn btn-outline-travel btn-equal-size me-2"
                >
                  Sign In
                </Link>
                <Link
                  to="/test/start"
                  className="btn btn-travel btn-equal-size btn-larger"
                >
                  Start Your Discovery
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

/* ===============================================
   3) Pinned‐Bar Header (Dynamic) for get-results
   =============================================== */
function PinnedBarHeader() {
    const [topShift, setTopShift] = useState(20);
    const [whiteBG, setWhiteBG] = useState(false);
  
    // Recalculate the pinned bar offset & background color
    const updatePinnedBar = () => {
      // 1) The scrolled amount
      const scrolled = window.scrollY;
  
      // 2) The height of the top header (which holds the logo)
      const headerEl = document.querySelector('.get-results-header');
      const headerHeight = headerEl ? headerEl.offsetHeight : 60;
  
      let newTop;
      if (window.innerWidth >= 768) {
        // (A) Desktop => original 20px offset
        newTop = 20 - scrolled;
      } else {
          newTop = headerHeight - scrolled;
      }
  
      // Ensure we never go negative
      if (newTop < 0) {
        newTop = 0;
      }
      setTopShift(newTop);
  
      // Also decide when to make pinned bar white
      // (same logic as your original code)
      if (headerEl) {
        setWhiteBG(scrolled >= headerHeight * 0.8);
      }
    };
  
    // Setup event listeners on mount
    useEffect(() => {
      window.addEventListener('scroll', updatePinnedBar);
      window.addEventListener('resize', updatePinnedBar);
      updatePinnedBar(); // run once on mount
  
      return () => {
        window.removeEventListener('scroll', updatePinnedBar);
        window.removeEventListener('resize', updatePinnedBar);
      };
    }, []);
  
    return (
      <>
        {/* (1) The top header with the logo. */}
        <div className="get-results-header">
          <div className="container header-container">
            <div className="navbar-brand fw-bold text-travel d-flex align-items-center">
              <img src={logo} alt="SoulCompass Logo" className="brand-logo" />
              <div className="brand-text d-flex flex-column align-items-start">
                <span>
                  SoulCompass<span className="domain">.net</span>™
                </span>
                <small className="text-muted">created by experts</small>
              </div>
            </div>
          </div>
        </div>
  
        {/* (2) The pinned dynamic result bar. */}
        <div
          className={`fixed-dynamic-result-bar ${whiteBG ? 'bg-white' : ''}`}
          style={{ top: `${topShift}px` }}
        >
          <HeaderDynamicResult />
        </div>
      </>
    );
  }
/* ===============================================
   4) MAIN HEADER COMPONENT: LOGIC
   =============================================== */
const Header = ({ isAuthenticated, onLogout, userName }) => {
  const location = useLocation();

  // Identify which route we’re on
  const isTestPage = location.pathname.startsWith('/test');
  const isGetResultsPage = location.pathname === '/test/get-results';
  const isTestProgress = location.pathname === '/test/progress';
  const isTestComplete = location.pathname === '/test/complete';
  // "Other test pages" means any /test route except get-results
  const isOtherTestPage = isTestPage && !isGetResultsPage;
  const [showPinnedBar, setShowPinnedBar] = useState(false);
  useEffect(() => {
    if (isGetResultsPage) {
      const timer = setTimeout(() => setShowPinnedBar(true), 9000);
      return () => clearTimeout(timer);
    }
  }, [isGetResultsPage]);
  /* -------------------------------------
   * 1. Normal (non‐test) routes
   *    => "FullNavHeader"
   -------------------------------------- */
  if (!isTestPage) {
    return (
      <FullNavHeader
        isAuthenticated={isAuthenticated}
        onLogout={onLogout}
        userName={userName}
      />
    );
  }

  /* -------------------------------------
   * 2. /test/get-results
   *    => Minimal Test Header for 9s,
   *       then PinnedBarHeader
   -------------------------------------- */
  if (isGetResultsPage) {
    return showPinnedBar ? <PinnedBarHeader /> : <MinimalTestHeader />;
  }


  /* -------------------------------------
   * 3. Other test routes
   *    (/test, /test/progress, /test/complete, etc.)
   *    => Minimal Test Header
   -------------------------------------- */
  if (isOtherTestPage || isTestProgress || isTestComplete) {
    return <MinimalTestHeader />;
  }

  // Fallback: If something else, show minimal or full?
  // Usually, we won’t get here. But let's default to full nav:
  return (
    <FullNavHeader
      isAuthenticated={isAuthenticated}
      onLogout={onLogout}
      userName={userName}
    />
  );
};

export default Header;
