import React, { useState, useEffect } from 'react';
import './LoaderAnimation.css';
import './ReportPage.css';
import adventureIcon from '../icons/adventure.svg';
import cultureIcon from '../icons/culture.svg';
import socialIcon from '../icons/social.svg';
import environmentIcon from '../icons/environment.svg';
import financialIcon from '../icons/financial.svg';
import resultsImage from '../components/results.svg';
import starsRating from '../icons/stars-rating.svg';
import cnnLogo from '../icons/cnn.svg';
import foxLogo from '../icons/fox.svg';
import forbesLogo from '../icons/forbes.svg';
import usaTodayLogo from '../icons/usa_today.svg';
import API_BASE_URL from "../config/apiConfig";

const personalityAspects = [
  { text: "Adventure", icon: adventureIcon },
  { text: "Culture", icon: cultureIcon },
  { text: "Social connections", icon: socialIcon },
  { text: "Eco-conscious", icon: environmentIcon },
  { text: "Financial", icon: financialIcon }
];

const availableResults = [
  {
    country: "GB",
    names: ["Emma", "Oliver", "Charlotte"],
    results: ["Adventurer’s Paradise", "Historic Explorer", "Luxury Seeker"]
  },
  {
    country: "FR",
    names: ["Hugo", "Julien", "Chloe"],
    results: ["Cultural Explorer", "Wine Lover", "Gastronomic Journey"]
  },
  {
    country: "IT",
    names: ["Sofia", "Luca", "Giulia"],
    results: ["Luxury Seeker", "Cultural Explorer", "Art Enthusiast"]
  },
  {
    country: "BR",
    names: ["Carlos", "Ana", "Marcos"],
    results: ["Beach Lover", "Carnival Enthusiast", "Adventurer’s Paradise"]
  }
];

const getRandomResult = () => {
  const randomCountry = availableResults[Math.floor(Math.random() * availableResults.length)];
  const randomName = randomCountry.names[Math.floor(Math.random() * randomCountry.names.length)];
  const randomResult = randomCountry.results[Math.floor(Math.random() * randomCountry.results.length)];
  return { country: randomCountry.country, name: randomName, result: randomResult };
};

const CircleFlag = ({ code, className }) => {
  const flagUrl = `https://hatscripts.github.io/circle-flags/flags/${code.toLowerCase()}.svg`;
  return (
    <img
      src={flagUrl}
      alt={`${code} flag`}
      className={className}
    />
  );
};

const ResultLoader = () => {
  const [isLoading, setIsLoading] = useState(false); // ⬅️ this tracks Stripe loading
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [currentAspect, setCurrentAspect] = useState(0);
  const [activeSmallIndex, setActiveSmallIndex] = useState(0);
  const [reportCount, setReportCount] = useState(3169);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [formData, setFormData] = useState({ name: '', email: '' }); // Added state for form

  const containerSize = 250;
  const smallSize = 20;
  const center = containerSize / 2;
  const radius = center;

  const colors = [
    "#FF8A65", "#7986CB", "#4DB6AC", 
    "#81C784", "#FFD54F", "#F48FB1",
    "#CE93D8", "#90A4AE"
  ];

  const smallCircleCenters = [];
  const smallCircles = Array.from({ length: 8 }).map((_, i) => {
    const angle = ((i * (360 / 8) - 90) * Math.PI / 180);
    const left = center + radius * Math.cos(angle) - smallSize / 2;
    const top = center + radius * Math.sin(angle) - smallSize / 2;
    smallCircleCenters.push({ x: left + smallSize / 2, y: top + smallSize / 2 });
    
    return (
      <div
        key={i}
        className={`small-circle ${activeSmallIndex === i ? 'active' : ''}`}
        style={{ left: `${left}px`, top: `${top}px`, backgroundColor: colors[i] }}
      >
        {i + 1}
      </div>
    );
  });

  const lines = smallCircleCenters.map((center, i) => {
    const targets = [
      smallCircleCenters[(i + 4) % 8],
      smallCircleCenters[(i + 3) % 8]
    ];
    
    return (
      <React.Fragment key={i}>
        {targets.map((target, j) => (
          <line
            key={`${i}-${j}`}
            className="connector-line"
            x1={center.x} y1={center.y}
            x2={target.x} y2={target.y}
          />
        ))}
      </React.Fragment>
    );
  });
// 📌 This goes near the other top-level useEffects
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetch(`${API_BASE_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.name && data.email) {
            setFormData({ name: data.name, email: data.email });
          }
        })
        .catch((err) => console.error("Error fetching profile for form:", err));
    }
  }, []);

  useEffect(() => {
    const personalityInterval = setInterval(() => {
      setCurrentAspect(prev => (prev + 1) % personalityAspects.length);
    }, 1000);

    const smallCircleInterval = setInterval(() => {
      setActiveSmallIndex(prev => (prev + 1) % 8);
    }, 1000);

    const timer = setTimeout(() => {
      setLoadingComplete(true);
    }, 9000);

    return () => {
      clearInterval(personalityInterval);
      clearInterval(smallCircleInterval);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const counterInterval = setInterval(() => {
      setReportCount(prev => prev + Math.floor(Math.random() * 10 + 1));
    }, 3000);
    return () => clearInterval(counterInterval);
  }, []);

  useEffect(() => {
    if (!loadingComplete) {
      localStorage.setItem('hideLatestResult', 'true');
    } else {
      localStorage.setItem('hideLatestResult', 'false');
    }
  }, [loadingComplete]);

  useEffect(() => {
    const updateResults = () => {
      const newResults = [];
      for (let i = 0; i < 3; i++) {
        newResults.push(getRandomResult());
      }
      setDisplayedResults(newResults);
    };

    updateResults();
    const intervalId = setInterval(updateResults, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    const sessionId = localStorage.getItem("session_id");
    if (!sessionId) {
      alert("Session ID is missing. Please complete the test first.");
      setIsLoading(false);
      return;
    }
    localStorage.setItem("name", formData.name);
    localStorage.setItem("email", formData.email);
    try {
      // 👇 This is the updated part
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` })  // <- ✅ add token if exists
      };
  
      const res = await fetch(`${API_BASE_URL}/create-checkout-session`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          session_id: sessionId,
          name: formData.name,
          email: formData.email,
        }),
      });
      const data = await res.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        alert("Payment initialization failed.");
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Stripe Checkout error:", err);
      alert("Something went wrong. Please try again.");
      setIsLoading(false);
    }
  };

  if (loadingComplete) {
    return (
      <div className="report-page-wrapper">
        <div className="primary-container">
          <div className="report-content">
            <div className="primary-split">
              <div className="left-section">
                <div className="report-title">
                  <h1>Your detailed Travel Personality report is ready !</h1>
                </div>
                <div className="mobile-results-image">
                  <img src={resultsImage} alt="Results" className="results-image" />
                </div>
                <ul className="benefits-list">
                  <li>
                    <svg viewBox="0 0 24 24" style={{width:"1.7rem", height:"1.7rem"}} role="presentation" className="flex-none check">
                      <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" style={{fill:"#008558"}}></path>
                    </svg>
                    <div>
                      <strong>Deep dive into your Travel Personality</strong>
                      <p>Discover how your unique traits dictate your ideal travel experiences and destinations with our in-depth analysis.</p>
                    </div>
                  </li>
                  <li>
                    <svg viewBox="0 0 24 24" style={{width:"1.7rem", height:"1.7rem"}} role="presentation" className="flex-none check">
                      <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" style={{fill:"#008558"}}></path>
                    </svg>
                    <div>
                      <strong>Tailored recommendations FOR YOU</strong>
                      <p>Get personalized destination suggestions that perfectly align with your personality, ensuring every trip is memorable and fitting.</p>
                    </div>
                  </li>
                  <li>
                    <svg viewBox="0 0 24 24" style={{width:"1.7rem", height:"1.7rem"}} role="presentation" className="flex-none check">
                      <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" style={{fill:"#008558"}}></path>
                    </svg>
                    <div>
                      <strong>Enhance your travel satisfaction</strong>
                      <p>Understanding your travel personality helps you choose experiences that will truly resonate with you, enhancing overall satisfaction on every journey.</p>
                    </div>
                  </li>
                </ul>
                <div className="assessment-fee-box">
                  <svg viewBox="0 0 24 24" style={{width:"1.5rem", height:"1.5rem"}} role="presentation" className="flex-none check">
                    <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" style={{fill:"#000D4D73"}}></path>
                  </svg>
                  <div>
                    <strong>Access with a small assessment fee.</strong>
                    <p>Unlock the full potential of your travel persona with our in-depth analysis, available for a small fee. This investment ensures you receive precise and personalized travel insights.</p>
                  </div>
                </div>
              </div>
              <div className="right-section">
                <img src={resultsImage} alt="Results" className="results-image" />
              </div>
            </div>
          </div>
        </div>

        <div className="cta-container-outer">
          <div className="cta-container">
            <div className="cta-split">
              <div className="cta-left-container">
                <div className="cta-left">
                  <h2 className="cta-title">Uncover your Travel Personality and your Personalized Destination !</h2>
                  <p className="reports-counter">
                    <span className="counter-wrapper">
                      {reportCount.toString().padStart(6, '0').split("").map((digit, index) => (
                        <span key={index} className="digit-box">{digit}</span>
                      ))}
                    </span>
                    <span className="reports-text">reports ordered!</span>
                  </p>
                  <div className="pricing">
                    <div className="pricing-content">
                      <div className="pricing-title">
                        <span>Travel Personality Report</span>
                        <span>+ Tailored Destination</span>
                      </div>
                      <div className="price-divider"></div>
                      <div className="price-info">
                        <span className="price">€2.95</span>
                        <span className="price-subtext">One-time payment</span>
                      </div>
                    </div>
                    {/* Added Form */}
                    <form onSubmit={handleFormSubmit}>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        placeholder="Full Name"
                        required
                        className="cta-input"
                      />
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        placeholder="Email Address"
                        required
                        className="cta-input"
                      />
                          <button
                            type="submit"
                            className={`cta-button ${isLoading ? 'disabled' : ''}`}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <span>
                                <span className="spinner" /> Securing checkout...
                              </span>
                            ) : (
                              "Get my results"
                            )}
                          </button>
                    </form>
                    <div className="trust-indicators">
                      <div className="trust-item">
                        <svg viewBox="0 0 24 24" className="trust-icon">
                          <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"></path>
                        </svg>
                        30‑day satisfaction guarantee
                      </div>
                      <div className="trust-item">
                        <svg viewBox="0 0 24 24" className="trust-icon">
                          <path d="M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10C4,8.89 4.89,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z"></path>
                        </svg>
                        <span>100% Secure</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cta-right">
                <h3 className="what-you-get-title">Here's what you'll get:</h3>
                <ul className="what-you-get">
                  <li>
                    <div className="loader-icon-circle">
                      <svg viewBox="0 0 24 24">
                        <path d="M14,17H7V15H14M17,13H7V11H17M17,9H7V7H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
                      </svg>
                    </div>
                    <span>Comprehensive and detailed travel personality report</span>
                  </li>
                  <li>
                    <div className="loader-icon-circle">
                      <svg viewBox="0 0 24 24">
                        <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                      </svg>
                    </div>
                    <span>Personalized destination and trip recommendations</span>
                  </li>
                  <li>
                    <div className="loader-icon-circle">
                      <svg viewBox="0 0 24 24">
                        <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                      </svg>
                    </div>
                    <span>Enhanced travel satisfaction</span>
                  </li>
                  <li>
                    <div className="loader-icon-circle">
                      <svg viewBox="0 0 24 24">
                        <path d="M3.5,18.49L9.5,12.48L13.5,16.48L22,6.92L20.59,5.51L13.5,13.48L9.5,9.48L2,16.99L3.5,18.49Z" />
                      </svg>
                    </div>
                    <span>Actionable travel insights</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="as-seen-on">
              <div className="line-with-text">
                <span>As seen on</span>
              </div>
              <div className="logos">
                <img src={cnnLogo} alt="CNN" className="as-seen-logo-big" />
                <img src={foxLogo} alt="FOX" className="as-seen-logo-big" />
                <img src={forbesLogo} alt="Forbes" className="as-seen-logo" />
                <img src={usaTodayLogo} alt="USA Today" className="as-seen-logo" />
              </div>
            </div>
          </div>
        </div>

        <div className="reviews-section">
          <div className="reviews-container">
            <h3 className="reviews-title">Rated 4.8/5 by our travelers</h3>
            <div className="stars-container">
              {[...Array(5)].map((_, index) => (
                <img key={index} src={starsRating} alt="star" className="stars-iconH" />
              ))}
            </div>
            <div className="review-grid">
              <div className="review-item">
                <div className="stars-container">
                  {[...Array(5)].map((_, index) => (
                    <img key={index} src={starsRating} alt="star" className="stars-icon" />
                  ))}
                </div>
                <p className="review-text">"I was skeptical at first, but the insights were incredibly accurate! I never realized how much my personality influenced my travel choices—the recommendations were spot on."</p>
              </div>
              <div className="review-item">
                <div className="stars-container">
                  {[...Array(5)].map((_, index) => (
                    <img key={index} src={starsRating} alt="star" className="stars-icon" />
                  ))}
                </div>
                <p className="review-text">"I've always struggled with picking the right destinations, but this test made it effortless and fun. I wish I had found it earlier—traveling now feels more fulfilling and meaningful!"</p>
              </div>
              <div className="review-item">
                <div className="stars-container">
                  {[...Array(5)].map((_, index) => (
                    <img key={index} src={starsRating} alt="star" className="stars-icon" />
                  ))}
                </div>
                <p className="review-text">"This quiz helped me discover destinations that truly match my personality. The insights were eye-opening, making my travels more personal and rewarding."</p>
              </div>
            </div>
            <div className="trust-badges">
              <h3 className="reviews-title">Trusted by Over 100,000 Travelers Worldwide</h3>
            </div>
            <div className="latest-results">
              <p className="reviews-title">Latest Results</p>
              <div className="review-grid">
                {displayedResults.map((item, index) => (
                  <div key={index} className="review-item">
                    <CircleFlag code={item.country} className="circle-flag" />
                    <div className="result-text">
                      <p className="review-text">
                        {item.name} just received their report:<br />
                        <strong>{item.result}</strong>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="cta-fullwidth">
              <div className="cta-full-left">
                <h2 className="cta-title">Uncover your Travel Personality and your Personalized Destination!</h2>
                <p className="reports-counter">
                  <span className="counter-wrapper">
                    {reportCount.toString().padStart(6, '0').split("").map((digit, index) => (
                      <span key={index} className="digit-box">{digit}</span>
                    ))}
                  </span>
                  <span className="reports-text">reports ordered!</span>
                </p>
              </div>
              <div className="cta-full-right">
                <div className="pricing">
                  <div className="pricing-content">
                    <div className="pricing-title">
                      <span>Travel Personality Report</span>
                      <span>+ Tailored Destination</span>
                    </div>
                    <div className="price-divider"></div>
                    <div className="price-info">
                      <span className="price">€2.95</span>
                      <span className="price-subtext">One-time payment</span>
                    </div>
                  </div>
                  {/* Added Form for Full-Width CTA */}
                  <form onSubmit={handleFormSubmit}>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      placeholder="Full Name"
                      required
                      className="cta-input"
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      placeholder="Email Address"
                      required
                      className="cta-input"
                    />
                        <button
                          type="submit"
                          className={`cta-button ${isLoading ? 'disabled' : ''}`}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <span>
                              <span className="spinner" /> Securing checkout...
                            </span>
                          ) : (
                            "Get my results"
                          )}
                        </button>
                  </form>
                  <div className="trust-indicators">
                    <div className="trust-item">
                      <svg viewBox="0 0 24 24" className="trust-icon">
                        <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"></path>
                      </svg>
                      30‑day satisfaction guarantee
                    </div>
                    <div className="trust-item">
                      <svg viewBox="0 0 24 24" className="trust-icon">
                        <path d="M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10C4,8.89 4.89,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z"></path>
                      </svg>
                      <span>100% Secure</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="results-loader">
      <div className="big-circle-container">
        <svg className="connector-svg" viewBox={`0 0 ${containerSize} ${containerSize}`}>
          {lines}
        </svg>
        {smallCircles}
        <div className="info-container">
          <div className="aspect-carousel">
            {personalityAspects.map((aspect, i) => (
              <div key={i} className={`aspect-item ${i === currentAspect ? 'active' : ''}`}>
                <img src={aspect.icon} alt={aspect.text} className="aspect-icon" />
                <span>{aspect.text}</span>
              </div>
            ))}
          </div>
          <div className="skeleton-lines">
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
          </div>
        </div>
      </div>
      <div className="my-progress-container">
        <div className="my-progress-bar"></div>
      </div>
      <div className="progress-meta">
        we are analysing your answers and preparing your personality type report ...
      </div>
    </div>
  );
};

export default ResultLoader;