import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Pricing from "./pages/Pricing";
import FAQs from "./pages/FAQs";
import ContactUs from "./pages/ContactUs";
import StartTest from "./pages/StartTest"; // NEW: Loading & Guidelines
import Test from "./pages/Test"; 
import SignIn from "./components/SignIn";
// Removed: import Signup from "./components/Signup";
import Profile from "./components/Profile";
// import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ScrollToTop from "./components/ScrollToTop";
import TestComplete from "./pages/TestComplete";
import API_BASE_URL from "./config/apiConfig";
import ResultLoader from './pages/ResultLoader';
import Success from './pages/Success';
import Share from "./pages/Share";
import Invite from "./pages/invite";
import ShareCompass from "./pages/ShareCompass";
import CompassInvite from "./pages/CompassInvite";
import { HelmetProvider } from 'react-helmet-async';
import WaitingPage from './pages/WaitingPage';
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const Minimal = ({ children }) => <>{children}</>;
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetch(`${API_BASE_URL}/profile`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.name) {
            setIsAuthenticated(true);
            setUserName(data.name);
          } else if (data.email) {
            setIsAuthenticated(true);
            setUserName(data.email.split("@")[0]);
          } else {
            localStorage.removeItem("token");
            setIsAuthenticated(false);
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
          setIsAuthenticated(false);
        });
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    const token = localStorage.getItem("token");
    fetch(`${API_BASE_URL}/profile`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.name) {
          setUserName(data.name);
        } else if (data.email) {
          setUserName(data.email.split("@")[0]);
        }
      })
      .catch((err) => console.error("Profile fetch error:", err));
    navigate("/"); // ✅ Redirect to Home after login
  };

  const handleRegister = () => {
    navigate("/"); // ✅ Redirect to Home after signup
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUserName("");
    navigate("/");
  };

  return (
    <>
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        {/* ✅ Public Routes */}
        <Route
          path="/"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
              <Home isAuthenticated={isAuthenticated} />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
              <About />
            </Layout>
          }
        />
        <Route path="/forgot-password" element={<Minimal><ForgotPassword /></Minimal>} />
        <Route path="/reset-password" element={<Minimal><ResetPassword /></Minimal>} />
        <Route
          path="/pricing"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
              <Pricing />
            </Layout>
          }
        />
        <Route
          path="/faqs"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
              <FAQs />
            </Layout>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
              <ContactUs />
            </Layout>
          }
        />
        {/* ✅ Authentication Routes */}
        <Route
          path="/signin"
          element={
            isAuthenticated ? (
              <Navigate to="/" /> // ✅ Redirect to Home after login
            ) : (
              <Layout>
                <SignIn onLogin={handleLogin} />
              </Layout>
            )
          }
        />
        {/* Removed Signup route */}
        {/* ✅ Protected Routes */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
                <Profile />
              </Layout>
            </ProtectedRoute>
          }
        />
        {/* 
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        */}
        {/* ✅ Test Page (Accessible to Everyone) */}
        <Route
          path="/test/start"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout}>
              <StartTest />
            </Layout>
          }
        />
        <Route
          path="/test/progress"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout}>
              <Test />
            </Layout>
          }
        />
        <Route
          path="/test/complete"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout}>
              <TestComplete />
            </Layout>
          }
        />
        <Route 
          path="/test/get-results" 
          element={
            <Layout>
              <ResultLoader />
            </Layout>
          } 
        />
        <Route 
          path="/test/success" 
          element={
            <Layout>
              <Success />
            </Layout>
          } 
        />
        {/* ✅ Privacy Policy and Terms of Use */}
        <Route
          path="/privacy-policy"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route
          path="/terms"
          element={
            <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout} userName={userName}>
              <TermsAndConditions />
            </Layout>
          }
        />
        <Route
          path="/share"
          element={
            <Minimal>
              <Share />
            </Minimal>
          }
        />
        <Route
          path="/invite"
          element={
            <Minimal>
              <Invite />
            </Minimal>
          }
        />
        <Route
          path="/share-compass/:session_id"
          element={
            <Minimal>
              <ShareCompass />
            </Minimal>
          }
        />
        <Route
        path="/invite/:token"
        element={
          <Minimal>
            <CompassInvite />
          </Minimal>
        }
      />
        <Route
          path="/test/waiting"
          element={
            <Layout>
              <WaitingPage />
            </Layout>
          }
        />

        {/* ✅ Fallback Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

// ✅ Wrap App with Router
function AppWrapper() {
  return (
    <HelmetProvider>
      <Router>
        <App />
      </Router>
    </HelmetProvider>
  );
}

export default AppWrapper;
