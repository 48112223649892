import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p className="effective-date">Effective Date: February 24, 2025</p>
      <p>
        Welcome to the SoulCompass ("Service"). We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you access our website, take our test, and interact with our services.
      </p>
      <p>
        By using our Services, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy. If you do not agree, please discontinue use of our Services.
      </p>
      <p>
        For any questions, you can contact us at: <a href="mailto:contact@soulcompass.net">contact@soulcompass.net</a>
      </p>
      <hr />

      <h2>General Information</h2>
      <p>
        We offer on-demand services that allow you to complete a travel personality test designed to help you discover your travel personality and receive tailored destination recommendations based on your preferences. Our service is purely for entertainment and informational purposes, aiming to enhance your travel planning experience by providing personalized insights into your ideal vacation style.
      </p>
      <p>
        Please note that in providing our service, we do not process any sensitive personal information (e.g., details related to your health, race, sexual orientation, political views, etc.). The multiple-choice questions in our test focus on general travel preferences and behaviors, ensuring that they are non-intrusive and do not request private or personally sensitive details.
      </p>

      <h2>How We Generate Your Results</h2>
      <p>Our test results are generated using a combination of:</p>
      <ul>
        <li>Predefined scoring models designed by our team</li>
        <li>Automated processing of your responses</li>
        <li>AI-assisted analysis to match your preferences to suitable destinations</li>
        <li>Algorithmic classification into travel personality types</li>
      </ul>
      <p>While this process is automated, it incorporates human oversight through:</p>
      <ul>
        <li>Regular review of the scoring systems</li>
        <li>Periodic quality checks of generated results</li>
        <li>Human-designed criteria for all personality classifications</li>
      </ul>
      <p>
        This process is used solely for informational and entertainment purposes and does not have legal or similarly significant effects on you as defined under GDPR Article 22.
      </p>

      <h2>Profiling Information</h2>
      <p>
        When you take our test, we categorize your travel preferences to create a personalized travel profile. This constitutes "profiling" under GDPR, but is:
      </p>
      <ul>
        <li>Limited to travel preferences only</li>
        <li>Not combined with external data sources</li>
        <li>Used exclusively to deliver the service you requested</li>
        <li>Not used to make predictions about other aspects of your life</li>
      </ul>
      <p>
        By taking our test, you consent to this limited form of profiling. You can withdraw this consent at any time by contacting us at <a href="mailto:contact@soulcompass.net">contact@soulcompass.net</a>.
      </p>

      <h2>Table of Contents</h2>
      <ol>
        <li>Data Protection Officer Contact Details</li>
        <li>What Does This Privacy Policy Cover?</li>
        <li>How and Why Do We Collect Your Personal Information?</li>
        <li>How Do We Use Your Personal Information?</li>
        <li>When Do We Disclose Personal Information?</li>
        <li>How Do We Use Cookies and Similar Technologies?</li>
        <li>Do We Sell Personal Information?</li>
        <li>How Do We Protect and Transfer Information?</li>
        <li>How Long Do We Retain Information?</li>
        <li>What Are Your Privacy Rights?</li>
        <li>Appealing Decisions Related to Privacy Requests</li>
        <li>Do We Collect Information About Children?</li>
        <li>How Do We Use or Disclose Sensitive Information?</li>
        <li>Do We Offer Financial Incentives for Your Personal Information?</li>
        <li>Do We Respond to "Do Not Track" (DNT) Signals?</li>
        <li>How Do We Update This Privacy Policy?</li>
        <li>Additional Information for California Residents</li>
        <li>How Can You Contact Us?</li>
      </ol>

      <h3>1. Data Protection Officer Contact Details</h3>
      <p>
        If you have any questions or concerns about how we handle your data, please contact our Data Protection Officer (DPO):
      </p>
      <p>
        Email: <a href="mailto:contact@soulcompass.net">contact@soulcompass.net</a>
      </p>

      <h3>2. What Does This Privacy Policy Cover?</h3>
      <p>
        This Privacy Policy applies to all personal data collected through our SoulCompass Travel Personality test, including:
      </p>
      <ul>
        <li>Information you provide when taking the test</li>
        <li>Data collected when you visit our website or interact with our Services</li>
        <li>Email communications and customer support interactions</li>
        <li>How we process your data using automated means, including AI assistance</li>
      </ul>
      <p>
        It does not apply to third-party websites or services linked from our platform. We encourage you to review their privacy policies before sharing any personal data.
      </p>
      <p>
        Please also see our Terms of Use, which apply to your access and use of our website, Services, app, and products that you purchase or sign up for on SoulCompass.net.
      </p>

      <h3>3. How and Why Do We Collect Your Personal Information?</h3>
      <p>We collect different types of personal information for various purposes:</p>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Examples</th>
              <th>Purpose</th>
              <th>Legal Basis (GDPR)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Identifiers</td>
              <td>Name, email, IP address</td>
              <td>To create your profile, contact you, and personalize your test results.</td>
              <td>Contract performance, Legitimate interest</td>
            </tr>
            <tr>
              <td>Test Responses</td>
              <td>Your answers to travel-related questions</td>
              <td>To determine your travel personality and generate recommendations.</td>
              <td>Consent, Contract performance</td>
            </tr>
            <tr>
              <td>Device &amp; Browsing Data</td>
              <td>Browser type, operating system, referral URLs</td>
              <td>To improve website functionality, prevent fraud, and enhance user experience.</td>
              <td>Legitimate interest</td>
            </tr>
            <tr>
              <td>Marketing Preferences</td>
              <td>Your opt-in/out choices for newsletters</td>
              <td>To send travel-related insights, offers, and updates.</td>
              <td>Consent</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>We collect information when you:</p>
      <ul>
        <li>Take our Advanced Travel Personality test</li>
        <li>Subscribe to newsletters or promotional content</li>
        <li>Contact us via email or support requests</li>
        <li>Interact with our website through cookies or analytics tools</li>
      </ul>

      <h3>4. How Do We Use Your Personal Information?</h3>
      <p>We use your personal data for the following purposes:</p>
      <ul>
        <li>
          <strong>Providing and Improving Our Service</strong> – To generate personalized travel recommendations based on your test responses using both automated processing and human-designed algorithms.
        </li>
        <li>
          <strong>Marketing and Communication</strong> – To send newsletters, promotional offers, and travel insights (only if you opt in).
        </li>
        <li>
          <strong>Analytics and Optimization</strong> – To analyze user behavior, improve test results, and optimize our Services.
        </li>
        <li>
          <strong>Legal Compliance</strong> – To meet legal obligations, respond to authorities, and enforce our policies.
        </li>
      </ul>
      <p>
        <strong>Automated Processing &amp; Decision-Making</strong>
      </p>
      <p>
        While we use automated systems to analyze your responses and generate recommendations:
      </p>
      <ul>
        <li>These results are for entertainment and informational purposes only</li>
        <li>They do not produce legal or similarly significant effects on you</li>
        <li>The system follows criteria and scoring models designed by humans</li>
        <li>You can request information about how your results were generated</li>
        <li>You can request a new assessment or contest your results by contacting us</li>
      </ul>

      <h3>5. When Do We Disclose Personal Information?</h3>
      <p>We may share your data with:</p>
      <ul>
        <li>
          <strong>Service Providers</strong> – Third-party companies helping us operate our test (e.g., hosting providers, analytics tools).
        </li>
        <li>
          <strong>Legal Compliance</strong> – If required by law, we may disclose data to regulatory authorities.
        </li>
        <li>
          <strong>Business Transfers</strong> – If our business merges or is acquired, your data may be transferred as part of the transaction.
        </li>
      </ul>
      <p>We do not sell or rent your personal information.</p>

      <h3>6. How Do We Use Cookies and Similar Technologies?</h3>
      <p>We use cookies and tracking technologies to:</p>
      <ul>
        <li>Save test progress and results</li>
        <li>Analyze user behavior on our website</li>
        <li>Improve functionality and user experience</li>
      </ul>
      <p>You can manage or disable cookies in your browser settings.</p>

      <h3>7. Do We Sell Personal Information?</h3>
      <p>No, we do not sell, rent, or trade your personal information.</p>

      <h3>8. How Do We Protect and Transfer Information?</h3>
      <p>
        We use industry-standard security measures to protect your data, including encryption, secure servers, and access controls.
      </p>
      <p>
        If you access our services from outside the U.S., your data may be transferred internationally. We ensure compliance with applicable privacy laws for such transfers, including Standard Contractual Clauses where required under GDPR.
      </p>

      <h3>9. How Long Do We Retain Information?</h3>
      <p>
        We retain your personal information only as long as necessary for the purposes outlined in this Privacy Policy.
      </p>
      <p>
        If you delete your account, we will remove your data within 30 days, unless required by law to retain it longer.
      </p>

      <h3>10. What Are Your Privacy Rights?</h3>
      <p>Depending on your location, you may have the right to:</p>
      <ul>
        <li>Access your data</li>
        <li>Correct inaccurate information</li>
        <li>Request deletion of your data</li>
        <li>Withdraw consent for marketing communications</li>
        <li>Object to processing based on legitimate interests</li>
        <li>Request information about automated decision-making</li>
        <li>Request a human review of automated decisions</li>
        <li>Data portability (receiving your data in a structured format)</li>
      </ul>
      <p>
        To exercise these rights, contact us at <a href="mailto:contact@soulcompass.net">contact@soulcompass.net</a>.
      </p>
      <p>
        <strong>For European Users (GDPR)</strong>
      </p>
      <p>
        If you are in the European Economic Area, you have the right to:
      </p>
      <ul>
        <li>Request explanation of the logic involved in our automated processing</li>
        <li>Contest results generated by our systems</li>
        <li>Request human intervention to review your test results</li>
        <li>Object to profiling for direct marketing purposes</li>
      </ul>
      <p>We will respond to all requests within 30 days.</p>

      <h3>11. Appealing Decisions Related to Privacy Requests</h3>
      <p>If you are dissatisfied with our response to your privacy request, you have the right to:</p>
      <ul>
        <li>Request a review of our decision</li>
        <li>File a complaint with your local data protection authority</li>
        <li>Contact our Data Protection Officer for further assistance</li>
      </ul>

      <h3>12. Do We Collect Information About Children?</h3>
      <p>
        No, we do not knowingly collect data from children under 16 years old. If we discover such data, we will delete it immediately.
      </p>

      <h3>13. How Do We Use or Disclose Sensitive Information?</h3>
      <p>
        We do not collect or process sensitive data such as health, race, or political views.
      </p>

      <h3>14. Do We Offer Financial Incentives for Personal Information?</h3>
      <p>No, we do not offer financial rewards in exchange for personal data.</p>

      <h3>15. Do We Respond to "Do Not Track" (DNT) Signals?</h3>
      <p>
        Our website does not respond to browser-based DNT signals. However, we respect global privacy preferences where applicable.
      </p>

      <h3>16. How Do We Update This Privacy Policy?</h3>
      <p>
        We may update this Privacy Policy periodically. Any changes will be posted with a revised Effective Date at the top of the policy. If changes affect your rights, we will notify you via email or website notice.
      </p>

      <h3>17. Additional Information for California Residents</h3>
      <p>
        Under CCPA (California Consumer Privacy Act), California residents may:
      </p>
      <ul>
        <li>Request details about data collected and shared</li>
        <li>Opt-out of data-sharing activities</li>
        <li>Request deletion of their data</li>
      </ul>
      <p>
        To submit a request, email <a href="mailto:contact@soulcompass.net">contact@soulcompass.net</a>.
      </p>

      <h3>18. How Can You Contact Us?</h3>
      <p>For any questions or privacy-related concerns, reach out to:</p>
      <p><strong>SoulCompass</strong></p>
      <p>
        Email: <a href="mailto:contact@soulcompass.net">contact@soulcompass.net</a>
      </p>
      <p>
        This Privacy Policy ensures transparency in how we handle your personal data while delivering personalized travel recommendations. If you have concerns, feel free to contact us.
      </p>
      <p className="copyright">
        © 2025 SoulCompass.net™. All rights reserved
      </p>
    </div>
  );
};

export default PrivacyPolicy;
