import React, { useEffect, useState } from "react";
import "./Profile.css";
import API_BASE_URL from "../config/apiConfig";

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showReports, setShowReports] = useState(false);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("You are not logged in.");
          setLoading(false);
          return;
        }

        const response = await fetch(`${API_BASE_URL}/profile`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "Failed to fetch profile details");
        }

        setProfile(data);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/profile`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password: newPassword }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || "Update failed");

      alert("Password updated successfully!");
      setNewPassword("");
      setEditing(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleFetchReports = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to fetch reports.");
      }

      setReports(data);
      setShowReports(true);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <div className="profile-container">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="profile-container">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <h1>Welcome to Your Profile</h1>

      <div className="profile-card">
        <h2>Account Details</h2>
        <p><strong>Email:</strong> {profile.email}</p>

        {editing ? (
          <form onSubmit={handlePasswordUpdate}>
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              required
              minLength={6}
            />
            <div>
              <button type="submit">Save</button>
              <button type="button" onClick={() => setEditing(false)}>Cancel</button>
            </div>
          </form>
        ) : (
          <button className="btn-edit" onClick={() => setEditing(true)}>
            Change Password
          </button>
        )}
      </div>

      <div className="profile-actions">
        <h2>Your Saved Reports</h2>
        <p>Access your previously generated personality reports.</p>
        <button
          className="btn-access"
          onClick={() => (showReports ? setShowReports(false) : handleFetchReports())}
        >
          {showReports ? "Hide Reports" : "View Reports"}
        </button>

        {showReports && reports.length > 0 && (
          <ul className="report-list">
            {reports.map((report) => (
              <li key={report.id}>
                <a
                  href={`${API_BASE_URL}/secure-download/${report.download_token}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {report.title} — {new Date(report.created_at).toLocaleDateString()}
                </a>
              </li>
            ))}
          </ul>
        )}

        {showReports && reports.length === 0 && <p>No reports found.</p>}
      </div>
    </div>
  );
};

export default Profile;
