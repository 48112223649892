// Footer.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../components/LogoSVG.svg';

const Footer = () => {
  const [showFullDisclaimer, setShowFullDisclaimer] = useState(false);
  const toggleDisclaimer = () => setShowFullDisclaimer(!showFullDisclaimer);

  return (
    <footer className="footer-section py-5">
      <div className="container footer-container">
        {/* Top Divider */}
        <hr className="footer-divider top-divider" />

        {/* ===== Laptop Layout (≥992px) ===== */}
        <div className="d-none d-lg-block">
          <div className="row mb-4">
            {/* Branding */}
            <div className="col-12 col-md-4 text-md-start">
              <div className="footer-brand d-flex align-items-center">
                <img src={logo} alt="SoulCompass Logo" className="brand-logo" />
                <div className="brand-text d-flex flex-column ms-2">
                  <span>
                    <span className="fw-bold">SoulCompass</span>
                    <span className="domain">.net</span>™
                  </span>
                  <small className="text-muted">Created by experts</small>
                </div>
              </div>
              <p className="text-muted mt-3">
                Personalized travel recommendations crafted by specialists in psychology and travel planning, helping you discover your perfect journey.
              </p>
            </div>
            {/* Links in Three Columns */}
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-lg-4">
                  <ul className="list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/pricing">Pricing</Link></li>
                    <li><Link to="/faqs">FAQs</Link></li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <ul className="list-unstyled">
                    <li><Link to="/contact-us">Contacts</Link></li>
                    <li><Link to="/signin">Sign In</Link></li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <ul className="list-unstyled">
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/terms">Terms of Use</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ===== Small & Medium Layout (≥576px and <992px) ===== */}
        <div className="d-none d-sm-block d-lg-none">
          <div className="row mb-4 align-items-start">
            {/* Left: Branding */}
            <div className="col-sm-4 text-start">
              <div className="footer-brand d-flex align-items-center">
                <img src={logo} alt="SoulCompass Logo" className="brand-logo" />
                <div className="brand-text d-flex flex-column ms-2">
                  <span>
                    <span className="fw-bold">SoulCompass</span>
                    <span className="domain">.net</span>™
                  </span>
                  <small className="text-muted">Created by experts</small>
                </div>
              </div>
              <p className="text-muted mt-3">
                Personalized travel recommendations crafted by specialists in psychology and travel planning, helping you discover your perfect journey.
              </p>
            </div>
            {/* Right: Divider + Links */}
            <div className="col-sm-8">
              {/* Divider aligned with the links column */}
              <hr className="footer-divider-new" />
              {/* Links in two columns */}
              <div className="row">
                <div className="col-6">
                  <ul className="list-unstyled text-start">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/pricing">Pricing</Link></li>
                    <li><Link to="/faqs">FAQs</Link></li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-unstyled text-start">
                    <li><Link to="/contact-us">Contacts</Link></li>
                    <li><Link to="/signin">Sign In</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/terms">Terms of Use</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ===== Mobile Layout (xs, <576px) ===== */}
        <div className="d-block d-sm-none">
          <div className="mb-4 text-center">
            {/* Brand: rendered as a row with logo at left and text to its right */}
            <div className="footer-brand d-flex align-items-center justify-content-center">
              <img src={logo} alt="SoulCompass Logo" className="brand-logo" />
              <div className="brand-text d-flex flex-column align-items-start ms-2">
                <span>
                  <span className="fw-bold">SoulCompass</span>
                  <span className="domain">.net</span>™
                </span>
                <small className="text-muted">Created by experts</small>
              </div>
            </div>
            <p className="text-muted mt-3">
              Personalized travel recommendations crafted by specialists in psychology and travel planning, helping you discover your perfect journey.
            </p>
          </div>
          {/* Full-width Divider */}
          <hr className="footer-divider-new" />
          {/* Mobile Links – Two Columns */}
          <div className="row">
            <div className="col-6">
              <ul className="list-unstyled text-start">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/pricing">Pricing</Link></li>
                <li><Link to="/faqs">FAQs</Link></li>
              </ul>
            </div>
            <div className="col-6">
              <ul className="list-unstyled text-start">
                <li><Link to="/contact-us">Contacts</Link></li>
                <li><Link to="/signin">Sign In</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/terms">Terms of Use</Link></li>
              </ul>
            </div>
          </div>
        </div>

        {/* ===== Disclaimer ===== */}
        <div className="row mt-4">
          <div className="col-12 text-muted small">
            <p>
              Disclaimer: The site does not offer professional or definitively accurate advice regarding products or services.&nbsp;
              {showFullDisclaimer && (
                <span>
                  The information and opinions provided are for entertainment and educational purposes only and should not replace professional advice. For professional advice, consult with appropriate specialists. Reliance on any information on this site is at your own risk.
                </span>
              )}
              &nbsp;
              <span onClick={toggleDisclaimer} className="disclaimer-toggle">
                {showFullDisclaimer ? 'Read less' : 'Read more'}
              </span>
            </p>
          </div>
        </div>

        {/* ===== Bottom Divider ===== */}
        <hr className="footer-divider bottom-divider" />

        {/* ===== Copyright ===== */}
        <div className="row">
          <div className="col-12">
            <p className="small text-muted text-center mb-0">
              © 2024 SoulCompass™. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
