import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../components/LogoSVG.svg';
import guidelinesIcon from '../components/guidelines.svg'; // Import SVG
import './StartTest.css';

const StartTest = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/api/ping').catch((err) => {
      console.warn("Backend ping failed (maybe still asleep):", err);
    });
    
    const progressElement = document.querySelector('.progress-bar-loading .progress');
    if (progressElement) {
      progressElement.style.width = '100%';
    }

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
      if (progressElement) {
        progressElement.style.width = '0%';
      }
    };
  }, []);

  const handleStartTest = () => {
    navigate('/test/progress');
  };

  return (
    <div className={`start-test-container ${isLoading ? 'centered' : 'flex-start'}`}>
      {isLoading ? (
        <div className="loading-screen">
          <div className="logo-spinner-container">
            <img 
              src={logo} 
              alt="Loading" 
              className="logo-spinner"
              style={{ width: '60px', height: '60px' }}
            />
          </div>
          <div className="progress-bar-loading">
            <div className="progress"></div>
          </div>
          <p className="loading-text">Your test is being prepared...</p>
        </div>
      ) : (
        <div className="guidelines-container">
          <img 
            src={guidelinesIcon} 
            className="guidelines-icon" 
            alt="Test guidelines" 
          />
          <h2>Test guidelines</h2>
          <ul className="guidelines-list">
            <li>Answer each statement based on your personal opinion</li>
            <li>You cannot skip questions, but you can return to them later</li>
          </ul>
          <button 
            className="start-test-button"
            onClick={handleStartTest}
          >
            Start test
          </button>
        </div>
      )}
    </div>
  );
};

export default StartTest;
