import React from "react";
import "./Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faMapMarkedAlt,
  faLightbulb,
  faBook,
  faAward,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Home = ({ isAuthenticated }) => {
  return (
    <div className="home">
      {/* HERO SECTION */}
      <div className="container-fluid hero-container">
        <div className="home-content">
          {/* Text block */}
          <div className="hero-text">
            <h1>
              Discover adventures that match{" "}
              <span className="highlight">your soul</span> with SoulCompass
            </h1>
            <p>
              Let your personality inspire meaningful travel recommendations.
            </p>
            <Link to="/test/start" className="btn-start">
              Start Your Discovery
            </Link>
            <p className="terms">
              By using this site, you agree with the{" "}
              <a href="/terms">terms &amp; conditions</a>.
            </p>
          </div>
          {/* Image block */}
          <div className="hero-image">
            <img
              src="/images/personality-phone.png"
              alt="Phone Preview"
              className="phone-image"
            />
          </div>
        </div>
      </div>

      {/* INFO SECTION */}
      <div className="container">
        <div className="info-section row">
          <div className="col-12 col-md-6">
            <div className="info-item info-item--personalized">
              <img
                src="/images/Cheerful_Person_guy.png"
                alt="Tailored Plans"
                className="info-image"
              />
              <h3>Get Personalized Travel Plans</h3>
              <p>
                Unlock travel suggestions tailored to your unique personality,
                crafted to align with your preferences, needs, and aspirations.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="info-item">
              <img
                src="/images/Cheerful_Person_girl.png"
                alt="Actionable Insights"
                className="info-image"
              />
              <h3>Discover Key Insights About You</h3>
              <p>
                Gain valuable insights to help you understand yourself better and
                how your personality impacts your perfect travel experiences.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* STATS SECTION */}
      <div className="container">
        <div className="stats-section">
          <div className="row">
            {/* Large text */}
            <div className="col-12 col-lg-7">
              <div className="stats-item stats-large">
                <p className="stats-highlight">
                  Discover the ideal trip that perfectly matches your unique
                  personality.
                </p>
              </div>
            </div>
            {/* Numbers */}
            <div className="col-12 col-lg-5">
              <div className="row">
                <div className="col-6">
                  <div className="stats-item stats-small">
                    <p className="stats-number">40</p>
                    <p className="stats-description">Questions</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="stats-item stats-small">
                    <p className="stats-number">150</p>
                    <p className="stats-description">
                      Data points analyzed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* HOW IT WORKS SECTION */}
      <div className="container">
        <div className="how-it-works">
          <h2>How it works</h2>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="step">
                <div className="step-circle">1</div>
                <h3>Prepare Yourself</h3>
                <p>
                  Ensure you're in a relaxed setting conducive to concentration
                  for the test.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="step">
                <div className="step-circle">2</div>
                <h3>Complete the Test</h3>
                <p>
                  Respond to 40 questions designed to reveal aspects of your
                  travel personality.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="step">
                <div className="step-circle">3</div>
                <h3>Receive Your Insights</h3>
                <p>
                  Access your report to explore your ideal travel plans and
                  discover new destinations.
                </p>
              </div>
            </div>
          </div>
          <div className="btn-container">
            <Link to="/test/start" className="btn-start">
              Start Your Discovery
            </Link>
          </div>
        </div>
      </div>

      {/* WHAT YOU WILL RECEIVE SECTION */}
      <div className="container">
        <div className="receive-section">
          <h2>What You Will Receive</h2>
          <div className="receive-container">
            <div className="receive-item">
              <div className="icon-circle">
                <FontAwesomeIcon icon={faFileAlt} />
              </div>
              <div className="receive-text">
                <h3>Personality Report</h3>
                <p>
                  A detailed report revealing how your personality influences your
                  travel preferences.
                </p>
              </div>
            </div>
            <div className="receive-item">
              <div className="icon-circle">
                <FontAwesomeIcon icon={faMapMarkedAlt} />
              </div>
              <div className="receive-text">
                <h3>Custom Travel Plans</h3>
                <p>
                  Travel itineraries crafted to suit your personality and
                  preferences perfectly.
                </p>
              </div>
            </div>
            <div className="receive-item">
              <div className="icon-circle">
                <FontAwesomeIcon icon={faLightbulb} />
              </div>
              <div className="receive-text">
                <h3>Actionable Insights</h3>
                <p>
                  Insights to help you understand yourself better and plan your
                  dream trips effectively.
                </p>
              </div>
            </div>
            <div className="receive-item">
              <div className="icon-circle">
                <FontAwesomeIcon icon={faBook} />
              </div>
              <div className="receive-text">
                <h3>Guides and Tips</h3>
                <p>
                  Access to travel guides and expert tips based on your
                  personality type.
                </p>
              </div>
            </div>
            <div className="receive-item">
              <div className="icon-circle">
                <FontAwesomeIcon icon={faAward} />
              </div>
              <div className="receive-text">
                <h3>Exclusive Recommendations</h3>
                <p>
                  Personalized destination recommendations tailored to your
                  unique preferences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EXPERTS SECTION */}
      <div className="container">
        <div className="experts-section">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="image-wrapper">
                <img
                  src="/images/Meeting.png"
                  alt="Experts Team"
                  className="experts-image"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="text-wrapper">
                <h2>Crafted by a Team of Experts</h2>
                <p>
                  Our recommendations are crafted with precision by a team of
                  experts in the fields of travel planning, psychology, and
                  personalized experiences. They work tirelessly to ensure every
                  plan is tailored to your unique preferences.
                </p>
                <h3>
                  Our commitment lies in providing precise evaluations that foster
                  self-awareness and comprehension for all our users.
                </h3>
                <Link to="/test/start" className="btn-start">
                  Start Your Discovery
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
