// HeaderDynamicResult.jsx
import React, { useState, useEffect } from 'react';
import CircleFlag from './CircleFlag';
import "./HeaderDynamicResultStyles.css";

// Example data pool for coherent results
const availableResults = [
  {
    country: "GB",
    names: ["Emma", "Oliver", "Charlotte"],
    results: ["Adventurer’s Paradise", "Historic Explorer", "Luxury Seeker"]
  },
  {
    country: "FR",
    names: ["Hugo", "Julien", "Chloe"],
    results: ["Cultural Explorer", "Wine Lover", "Gastronomic Journey"]
  },
  {
    country: "IT",
    names: ["Sofia", "Luca", "Giulia"],
    results: ["Luxury Seeker", "Cultural Explorer", "Art Enthusiast"]
  },
  {
    country: "BR",
    names: ["Carlos", "Ana", "Marcos"],
    results: ["Beach Lover", "Carnival Enthusiast", "Adventurer’s Paradise"]
  }
];

const getRandomResult = () => {
  const countryData = availableResults[Math.floor(Math.random() * availableResults.length)];
  const randomName = countryData.names[Math.floor(Math.random() * countryData.names.length)];
  const randomReport = countryData.results[Math.floor(Math.random() * countryData.results.length)];
  return { country: countryData.country, name: randomName, result: randomReport };
};

const HeaderDynamicResult = () => {
  const [result, setResult] = useState(getRandomResult());
  const [animClass, setAnimClass] = useState("");

  // Update the result every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setResult(getRandomResult());
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);
    // Whenever `result` changes, add the "fade-in-down" class to trigger animation
  useEffect(() => {
    setAnimClass("fade-in-down");
  }, [result]);

  // Once the animation finishes, remove the class so it's clean for the next update
  const handleAnimationEnd = () => {
    setAnimClass("");
  };
    // 1) Check localStorage to see if we should hide
  const hideLatest = localStorage.getItem('hideLatestResult');

  // 2) If it's "true", do not render the component at all
  if (hideLatest === 'true') {
    return null;
  }
  
  return (
    <div
      className={`header-dynamic-result ${animClass}`}
      onAnimationEnd={handleAnimationEnd}
    >
      <CircleFlag code={result.country} className="header-flag" />
      <span className="header-result-text">
        {result.name} just received their report: <strong>{result.result}</strong>
      </span>
    </div>
  );
};

export default HeaderDynamicResult;