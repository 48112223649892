import React, { useState } from "react";
import "./ContactUs.css";
import API_BASE_URL from "../config/apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHeadset } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess("");
    setError("");

    try {
      const response = await fetch(`${API_BASE_URL}/contact`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to send message");
      }

      setSuccess("Message sent successfully!");
      setFormData({ name: "", email: "", message: "" });
    } catch (err) {
      setError(err.message || "Something went wrong");
    }
  };

  return (
    <div className="contact">
      <div className="contact-container">
        {/* Contact Info (appears on top on small screens) */}
        <div className="contact-info">
          <h1>We're Here to Help!</h1>
          <p>
            Got a question, feedback, or need assistance? We'd love to hear from
            you!
          </p>
          <div className="contact-details">
            <h3>Contact Information</h3>
            <p>
              <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
              Email: contact@soulcompass.net
            </p>
            <p>
              <FontAwesomeIcon icon={faHeadset} className="contact-icon" />
              Support: Available Mon-Fri, 9AM-6PM
            </p>
          </div>
        </div>

        {/* Contact Form */}
        <div className="contact-form">
          <h1>Get in touch</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit">Send message</button>
          </form>
          {success && <p className="success-message">{success}</p>}
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Contact;
