import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoaderAnimation.css';
import API_BASE_URL from "../config/apiConfig";
import adventureIcon from '../icons/adventure.svg';
import cultureIcon from '../icons/culture.svg';
import socialIcon from '../icons/social.svg';
import environmentIcon from '../icons/environment.svg';
import financialIcon from '../icons/financial.svg';
import { jwtDecode } from "jwt-decode";


const personalityAspects = [
  { text: "Adventure", icon: adventureIcon },
  { text: "Culture", icon: cultureIcon },
  { text: "Social connections", icon: socialIcon },
  { text: "Eco-conscious", icon: environmentIcon },
  { text: "Financial", icon: financialIcon }
];

const WaitingPage = () => {
  const navigate = useNavigate();
  const hasRunRef = useRef(false);
  const [currentAspect, setCurrentAspect] = useState(0);
  const [activeSmallIndex, setActiveSmallIndex] = useState(0);
  const [status, setStatus] = useState('loading'); // loading, processing, success, error
  const [errorMessage, setErrorMessage] = useState(null);

  const containerSize = 250;
  const smallSize = 20;
  const center = containerSize / 2;
  const radius = center;

  const colors = [
    "#FF8A65", "#7986CB", "#4DB6AC",
    "#81C784", "#FFD54F", "#F48FB1",
    "#CE93D8", "#90A4AE"
  ];

  const smallCircleCenters = [];
  const smallCircles = Array.from({ length: 8 }).map((_, i) => {
    const angle = ((i * (360 / 8) - 90) * Math.PI / 180);
    const left = center + radius * Math.cos(angle) - smallSize / 2;
    const top = center + radius * Math.sin(angle) - smallSize / 2;
    smallCircleCenters.push({ x: left + smallSize / 2, y: top + smallSize / 2 });
    return (
      <div
        key={i}
        className={`small-circle ${activeSmallIndex === i ? 'active' : ''}`}
        style={{ left: `${left}px`, top: `${top}px`, backgroundColor: colors[i] }}
      >
        {i + 1}
      </div>
    );
  });

  const lines = smallCircleCenters.map((center, i) => {
    const targets = [
      smallCircleCenters[(i + 4) % 8],
      smallCircleCenters[(i + 3) % 8]
    ];
    return (
      <React.Fragment key={i}>
        {targets.map((target, j) => (
          <line
            key={`${i}-${j}`}
            className="connector-line"
            x1={center.x} y1={center.y}
            x2={target.x} y2={target.y}
          />
        ))}
      </React.Fragment>
    );
  });

  useEffect(() => {
    const personalityInterval = setInterval(() => {
      setCurrentAspect(prev => (prev + 1) % personalityAspects.length);
    }, 1000);

    const smallCircleInterval = setInterval(() => {
      setActiveSmallIndex(prev => (prev + 1) % 8);
    }, 1000);

    return () => {
      clearInterval(personalityInterval);
      clearInterval(smallCircleInterval);
    };
  }, []);

  useEffect(() => {
    if (hasRunRef.current) return;
    hasRunRef.current = true;
  
    const tokenFromUrl = new URLSearchParams(window.location.search).get("token");
  
    if (!tokenFromUrl) {
      setErrorMessage("Missing token. Please return to the homepage and restart the process.");
      setStatus("error");
      return;
    }
  
    let decoded;
    try {
      decoded = jwtDecode(tokenFromUrl);
    } catch (err) {
      console.error("Invalid token:", err);
      setErrorMessage("Invalid token. Please return to the homepage and restart.");
      setStatus("error");
      return;
    }
  
    const email = localStorage.getItem("email");
    const name = localStorage.getItem("name");
  
    if (!decoded?.sub || !email || !name) {
      setErrorMessage("Missing required information. Please ensure you've completed the payment process.");
      setStatus("error");
      return;
    }
  
    localStorage.setItem("sessionToken", tokenFromUrl); // Save it for secure download later
  
    const completeGuestRegistration = async () => {
      setStatus("processing");
  
      const payload = {
        token: tokenFromUrl, // ✅ send the secure token
        email,
        name
      };
  
      try {
        const response = await fetch(`${API_BASE_URL}/complete-guest`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload)
        });
  
        if (!response.ok) {
          throw new Error("Failed to complete guest registration");
        }
  
        const data = await response.json();
        console.log("Complete guest response:", data);
  
        localStorage.removeItem("email");
        localStorage.removeItem("name");
  
        if (data.download_token) {
          localStorage.setItem("downloadToken", data.download_token);
        }
  
        setStatus("success");
        navigate("/test/success");
      } catch (error) {
        console.error("Error during guest account completion:", error);
        setErrorMessage("An error occurred while processing your request. Please try again later.");
        setStatus("error");
      }
    };
  
    completeGuestRegistration();
  }, [navigate]);
  

  // Render error state
  if (status === 'error') {
    return (
      <div className="results-loader">
        <h2>Oops, Something Went Wrong</h2>
        <p>{errorMessage}</p>
        <button onClick={() => navigate('/')} style={{ padding: '10px 20px', cursor: 'pointer' }}>
          Return to Home
        </button>
      </div>
    );
  }

  // Render loading/processing state
  return (
    <div className="results-loader">
      <div className="big-circle-container">
        <svg className="connector-svg" viewBox={`0 0 ${containerSize} ${containerSize}`}>
          {lines}
        </svg>
        {smallCircles}
        <div className="info-container">
          <div className="aspect-carousel">
            {personalityAspects.map((aspect, i) => (
              <div key={i} className={`aspect-item ${i === currentAspect ? 'active' : ''}`}>
                <img src={aspect.icon} alt={aspect.text} className="aspect-icon" />
                <span>{aspect.text}</span>
              </div>
            ))}
          </div>
          <div className="skeleton-lines">
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
          </div>
        </div>
      </div>
      <div className="waiting-progress-container">
        <div className="waiting-progress-bar"></div>
      </div>
      <div className="progress-meta">
        {status === 'processing'
          ? "We're putting the final touches on your personalized travel persona report..."
          : "Loading..."}
      </div>
    </div>
  );
};

export default WaitingPage;