import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "./ShareCompass.css";
import { Link } from "react-router-dom";

const Invite = () => {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const senderName = searchParams.get("from") || "Someone";

  const [chartUrl, setChartUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const getSessionIdFromToken = (token) => {
    try {
      const parts = token.split(".");
      if (parts.length !== 3) return null;
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.sub;
    } catch (e) {
      console.error("Failed to decode token:", e);
      return null;
    }
  };

  useEffect(() => {
    if (!token) {
      setError("Missing token in URL.");
      setLoading(false);
      return;
    }
    const sessionId = getSessionIdFromToken(token);
    if (!sessionId) {
      setError("Invalid or expired link");
      setLoading(false);
      return;
    }
    const imageUrl = `${process.env.REACT_APP_API_BASE_URL.replace("/api", "")}/static/compasses/${sessionId}_merged.png`;
    setChartUrl(imageUrl);
    setLoading(false);
  }, [token]);

  return (
    <div className="share-compass-container">
      <div className="share-compass-card">
        <h1>{`${senderName}'s SoulCompass`}</h1>
        <p className="subtext">
          {senderName === "Someone"
            ? "You’ve just received a glimpse into someone’s SoulCompass — a personal map of the traits that guide their adventures."
            : `You’ve just received a glimpse into ${senderName}’s SoulCompass — a personal map of the traits that guide their adventures.`}
        </p>

        {loading ? (
          <p>Loading chart...</p>
        ) : error ? (
          <p className="error-text">⚠️ {error}</p>
        ) : (
          <img
            src={chartUrl}
            alt="Shared Travel Compass"
            className="chart-image"
          />
        )}

        <div className="share-section">
          <p className="cta-text">Curious to discover your own?</p>
          <Link to="/test/start" className="cta-button">
            Start Your Discovery
          </Link>
          <div className="hashtag">#SoulCompassJourney</div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
