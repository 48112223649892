import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChartLine,
  faSmile,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import "./About.css";

const About = () => {
  const navigate = useNavigate(); // Hook for navigation

  // Function to handle button click and navigate to the Test component
  const handleDiscovery = () => {
    navigate("/test/start"); // Navigate to the Test component
  };

  return (
    <div className="about">
      {/* Top Section */}
      <div className="about-top-section">
        <h1>Transforming Travel with SoulCompass Insights</h1>
        <p>
          Welcome to SoulCompass, the ultimate destination for personalized travel recommendations.
          Combining advanced personality analysis with travel expertise, we aim to empower you to discover
          trips that align with your unique preferences, interests, and aspirations. By uncovering your distinct
          personality traits, we help create travel experiences that resonate with who you truly are, ensuring every journey is meaningful and memorable.
        </p>
      </div>

      {/* Mission Section */}
      <div className="mission-section">
        <div className="mission-image">
          <img src="/images/About.png" alt="Expert Guide" />
        </div>
        <div className="mission-content">
          <h4>Our Mission</h4>
          <h2>Discover the Essence of Personalized Travel with SoulCompass</h2>
          <p>
            Our mission is to bridge the gap between self-discovery and exploration. By analyzing
            your unique personality traits, we craft bespoke travel plans that cater to your individuality. 
            We believe travel is not just about destinations but about meaningful experiences that 
            reflect who you truly are.
          </p>
        </div>
      </div>

      {/* Why Our Test Stands Out Section */}
      <div className="test-stands-out">
        <h2 className="section-title">Why SoulCompass Stands Out</h2>
        <h1 className="section-highlight">
          Personalization, Depth, and Precision
        </h1>
        <div className="benefits-container">
          <div className="benefit-item">
            <div className="icon-circle-about">
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <h3 className="benefit-title">Scientifically Validated</h3>
            <p className="benefit-description">
              Backed by rigorous research, ensuring precise and reliable results tailored to your travel needs.
            </p>
          </div>
          <div className="benefit-item">
            <div className="icon-circle-about">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
            <h3 className="benefit-title">Deep Insights</h3>
            <p className="benefit-description">
              Gain an in-depth understanding of your preferences and motivations for crafting unforgettable trips.
            </p>
          </div>
          <div className="benefit-item">
            <div className="icon-circle-about">
              <FontAwesomeIcon icon={faSmile} />
            </div>
            <h3 className="benefit-title">User-Friendly</h3>
            <p className="benefit-description">
              Designed with clarity and simplicity, making it accessible and enjoyable for all users.
            </p>
          </div>
          <div className="benefit-item">
            <div className="icon-circle-about">
              <FontAwesomeIcon icon={faLightbulb} />
            </div>
            <h3 className="benefit-title">Expert Guidance</h3>
            <p className="benefit-description">
              Personalized recommendations powered by expert analysis to help you plan the perfect itinerary.
            </p>
          </div>
        </div>
      </div>

      {/* Commitment Section */}
      <div className="commitment-section">
        <div className="commitment-image">
          <img src="/images/About_lady.png" alt="Commitment to You" />
        </div>
        <div className="commitment-content">
          <h4 className="commitment-title">Our Commitment to You</h4>
          <h2>Explore, Connect, and Grow with SoulCompass</h2>
          <p>
            At SoulCompass, we are committed to guiding you on a journey of self-discovery and travel. 
            Our platform combines personality-driven insights with expert advice to create tailor-made trips. 
            With a focus on your individuality, we ensure that every travel experience reflects your true self.
          </p>
        </div>
      </div>

      {/* Community Section */}
      <div className="community-section">
        <h4>Join Our Community</h4>
        <h2>Discover, Belong, and Explore</h2>
        <p>
          Join a vibrant community of travel enthusiasts who share your passion for meaningful journeys. 
          Engage with resources designed to help you uncover your travel personality and plan trips that 
          reflect your unique traits. Discover how travel can become a transformative experience, with our 
          expert insights guiding your way.
        </p>
        <button className="btn-join" onClick={handleDiscovery}>
          Start Your Discovery
        </button>
      </div>
    </div>
  );
};

export default About;
