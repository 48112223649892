import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Invite.css";

const Invite = () => {
  const [searchParams] = useSearchParams();
  const refName = searchParams.get("ref");

  const sharedBy = refName
    ? `${refName.charAt(0).toUpperCase()}${refName.slice(1)}`
    : "A friend";

  const pageUrl = `${process.env.REACT_APP_FRONTEND_BASE_URL}/invite?ref=${refName || "a-friend"}`;
  const previewImageUrl = "/invite-preview.png"; // Served from public folder

  return (
    <div className="invite-page">
      <Helmet>
        <title>Discover Your Travel Persona | SoulCompass</title>
        <meta name="description" content="You were invited to uncover the travel style that reflects your soul." />
        <meta property="og:title" content="Your SoulCompass Journey Starts Here" />
        <meta property="og:description" content={`${sharedBy} thought you’d enjoy discovering your travel persona.`} />
        <meta property="og:image" content={previewImageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your SoulCompass Journey Starts Here" />
        <meta name="twitter:description" content={`${sharedBy} thought you’d enjoy discovering your travel persona.`} />
        <meta name="twitter:image" content={previewImageUrl} />
      </Helmet>

      <div className="invite-content">
        <img src={previewImageUrl} alt="SoulCompass Preview" className="invite-image" />
        <h1>Your SoulCompass Journey Starts Here</h1>
        <p className="invite-subheading">
          {sharedBy} thought you’d love this.
        </p>
        <p className="invite-description">
          <strong>SoulCompass unveils your unique travel persona</strong> — shaped by the places, patterns, and feelings that have always called to you.<br /><br />
          The journey ahead is not random. It’s crafted — entirely around you. Your instincts. Your rhythm. Your soul.
        </p>
        <Link to="/test/start" className="cta-button">
          Start Your Discovery
        </Link>
      </div>
    </div>
  );
};

export default Invite;