// CircleFlag.js
import React from 'react';

const CircleFlag = ({ code, className }) => {
  const flagUrl = `https://hatscripts.github.io/circle-flags/flags/${code.toLowerCase()}.svg`;

  return <img src={flagUrl} alt={`${code} flag`} className={className} />;
};

export default CircleFlag;
