import React from "react";
import { Link, useNavigate } from "react-router-dom";
import successIcon from "../components/success.svg"; // Adjust the path as needed
import './TestComplete.css';
// Remove jwtDecode import if not needed (or use it as appropriate)
import API_BASE_URL from "../config/apiConfig";

const TestComplete = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const handleGetResults = async () => {
    if (isLoading) return; // Prevent multiple clicks
    setIsLoading(true);
    // Retrieve the saved test progress (answers) from session storage
    const savedProgress = sessionStorage.getItem("testProgress");
    if (!savedProgress) {
      console.error("No test progress found.");
      return;
    }
    const { savedAnswers } = JSON.parse(savedProgress);
    console.log("Submitting test with answers:", savedAnswers);
    
    // Determine endpoint and payload based on authentication
    const token = localStorage.getItem("token");
    let endpoint = "";
    let payload = {};
    let headers = {
      "Content-Type": "application/json"
    };

    if (token) {
      // Authenticated user flow
      endpoint = `${API_BASE_URL}/submit-test`;
      payload = {
        answers: savedAnswers
      };
      headers["Authorization"] = `Bearer ${token}`;
    } else {
      // Guest flow: send only answers
      endpoint = `${API_BASE_URL}/submit-test-guest`;
      payload = {
        answers: savedAnswers
      };
    }
    
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error("Failed to submit test");
      }
      const data = await response.json();
      console.log("Submission response:", data);
      
      // For guest submissions, store the session token if provided
      if (data.session_id) {
        localStorage.setItem("session_id", data.session_id);
      }
      
      // After submission, navigate to the next step 
      navigate("/test/get-results");
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  return (
    <div className="container-fluid test-complete-wrapper">
      <div className="row justify-content-center align-items-start pt-3">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="test-complete-container p-4">
            <img src={successIcon} alt="Success" className="img-fluid success-icon mb-3" />
            <h1 className="congratulations-heading">Congratulations!</h1>
            <p>You've completed the personality test.</p>
            <div className="navigation-buttons">
            <button
              className={`cta-button ${isLoading ? 'disabled' : ''}`}
              onClick={handleGetResults}
              disabled={isLoading}
            >
              {isLoading ? (
                <span>
                  <span className="spinner" /> Preparing your analysis...
                </span>
              ) : (
                "Get my results"
              )}
            </button>
            </div>
            <div className="edit-answers">
              <Link to="/test/progress" className="btn btn-link text-decoration-underline">
                Edit my answers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestComplete;
