import React, { useState } from "react";
import "./SignIn.css";
import API_BASE_URL from "../config/apiConfig"; // Import API base URL
import { toast } from "react-toastify"; // Toast notifications
import "react-toastify/dist/ReactToastify.css"; // Toastify styles
import { useNavigate } from "react-router-dom"; // For navigation
import { Link } from "react-router-dom";


const SignIn = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate(); // Navigation hook

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Clear any existing error
    setLoading(true); // Start loading

    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      setLoading(false); // Stop loading

      if (response.ok) {
        // Save token and user name to localStorage
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("name", data.name);

        toast.success(`Welcome back, ${data.name}!`); // Toast success notification
        if (onLogin && typeof onLogin === "function") {
          onLogin(); // Notify parent component
        }

        // Redirect the user to the home page (or any desired page)
        navigate("/");
      } else {
        setError(data.message || "Invalid login credentials");
        toast.error(data.message || "Login failed. Please try again."); // Toast error notification
      }
    } catch (err) {
      setLoading(false); // Stop loading
      setError("Something went wrong. Please try again.");
      toast.error("Something went wrong. Please try again."); // Toast error notification
    }
  };

  return (
    <div className="sign-in">
      <h1 className="sign-in-title">Sign into your account</h1>
      <form onSubmit={handleLogin} className="sign-in-form">
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          autoComplete="email" 
        />
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          autoComplete="current-password"
        />
        <div className="form-options">
          <label>
            <input type="checkbox" />
            Remember me
          </label>
          <Link to="/forgot-password" className="forgot-password">
            Forgot password?
          </Link>
        </div>
        <button type="submit" className="btn-sign-in" disabled={loading}>
          {loading ? "Signing in..." : "Sign in"}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default SignIn;
