import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./ShareCompass.css";
import { FaTwitter, FaFacebookF, FaWhatsapp, FaDownload, FaShare } from "react-icons/fa";

const ShareCompass = () => {
  const { session_id } = useParams();
  const location = useLocation();
  const cleanSessionId = session_id.replace(/^:/, "");

  const [chartUrl, setChartUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [hasShared, setHasShared] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const senderName = queryParams.get("from") || "Someone";
    const constructedLink = `https://soulcompass.net/invite/${cleanSessionId}?from=${encodeURIComponent(senderName)}`;
    setShareLink(constructedLink);
  }, [cleanSessionId, location.search]);

  useEffect(() => {
    if (cleanSessionId) {
      const mergedChartUrl = `${process.env.REACT_APP_API_BASE_URL.replace("/api", "")}/static/compasses/${cleanSessionId}_merged.png`;
      setChartUrl(mergedChartUrl);
    }
  }, [cleanSessionId]);

  useEffect(() => {
    if (window.innerWidth < 768 && navigator.share && shareLink && !hasShared) {
      const timeout = setTimeout(() => {
        navigator
          .share({
            title: "My SoulCompass Chart",
            text: "Check out my personalized travel compass from SoulCompass!",
            url: shareLink,
          })
          .then(() => setHasShared(true))
          .catch((err) => {
            console.log("Share cancelled or failed:", err);
          });
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [shareLink, hasShared]);

  return (
    <div className="share-compass-container">
      <div className="share-compass-card">
        <h1>The compass of You</h1>
        <p className="subtext">
          This is your unique travel compass — the traits that guide your journey.
        </p>

        {chartUrl ? (
          <img src={chartUrl} alt="Your Travel Compass" className="chart-image" />
        ) : (
          <p>Loading chart...</p>
        )}

        <div className="share-section">
          <p className="cta-text">Want to share it like Spotify Wrapped?</p>
          <p className="hashtag">#SoulCompassJourney</p>

          <div className="platform-buttons">
            <a
              href={`https://twitter.com/intent/tweet?text=Check%20out%20my%20SoulCompass%20travel%20chart!%20%23SoulCompassJourney&url=${shareLink}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn twitter"
              title="Share on X (Twitter)"
            >
              <FaTwitter />
            </a>

            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn facebook"
              title="Share on Facebook"
            >
              <FaFacebookF />
            </a>

            <a
              href={`https://api.whatsapp.com/send?text=Check%20out%20my%20SoulCompass%20travel%20chart!%20${shareLink}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn whatsapp"
              title="Share on WhatsApp"
            >
              <FaWhatsapp />
            </a>

            <a
              href={chartUrl}
              download={`soulcompass_${cleanSessionId}.png`}
              className="social-btn download"
              title="Download Chart"
            >
              <FaDownload />
            </a>

            {navigator.share && (
              <button
                className="social-btn device-share"
                onClick={() => {
                  navigator
                    .share({
                      title: "My SoulCompass Chart",
                      text: "Check out my personalized travel compass from SoulCompass!",
                      url: shareLink,
                    })
                    .catch((error) => {
                      console.log("Error sharing:", error);
                    });
                }}
                aria-label="Share via device"
              >
                <FaShare />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareCompass;