import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children, isAuthenticated, onLogout, userName }) => {
  const location = useLocation();
  // Hide the footer on any route that starts with "/test"
  const hideFooter = location.pathname.startsWith("/test");

  // Use a breakpoint of 576px to determine mobile view
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };

    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* Always render the header */}
      <Header
        isAuthenticated={isAuthenticated}
        onLogout={onLogout}
        userName={userName}
      />
      <main
        style={{
          flex: 1,
          padding: isMobile ? "0" : "20px 0",
          minHeight: "calc(80vh - 160px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </main>
      {/* Render the footer only if not on a test page */}
      {!hideFooter && <Footer />}
    </>
  );
};

export default Layout;
