import React, { useState } from "react";
import API_BASE_URL from "../config/apiConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
    setLoading(true);

    try {
      const res = await fetch(`${API_BASE_URL}/request-password-reset`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await res.json();

      if (res.ok) {
        toast.success("Reset link sent! Check your inbox.");
        setSuccess(true);
      } else {
        setError(data.message || "Request failed.");
        toast.error(data.message || "Request failed.");
      }
    } catch (err) {
      setError("Something went wrong.");
      toast.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-card">
        <h2>Forgot Your Password?</h2>
        <p>Enter your email and we’ll send you a link to reset it.</p>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="btn-request-reset"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Sending..." : "Send Reset Link"}
        </button>
        {success && <p className="success-text">✔ Reset link sent successfully.</p>}
        {error && <p className="error-text">⚠️ {error}</p>}
        <p className="back-to-login" onClick={() => navigate("/signin")}>
          ← Back to sign in
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
