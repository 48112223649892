import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms &amp; Conditions</h1>
      <p className="effective-date">Effective Date: February 24, 2025</p>
      <p>
        Welcome to SoulCompass! These Terms &amp; Conditions (“Terms”) govern your access to and use of our website, interactive test, mobile apps, and any related products or services (collectively, the “Services”). By accessing or using our Services, you agree to be bound by these Terms as well as our Privacy Policy and any additional policies referenced herein. If you do not agree with these Terms, please discontinue using our Services immediately.
      </p>

      <h2>1. Privacy Policy</h2>
      <p>
        We respect your privacy. Our Privacy Policy describes how we collect, use, and protect your personal information when you use our Services. By using SoulCompass, you acknowledge and agree that your information will be processed in accordance with our Privacy Policy.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        You must be at least 18 years old to use our Services. If you are accepting these Terms on behalf of an organization, you represent that you have the authority to bind that organization to these Terms. In such cases, “you” and “your” refer to that organization.
      </p>

      <h2>3. Nature of the Service</h2>
      <p>
        SoulCompass provides an interactive travel personality test that analyzes your responses and generates personalized travel recommendations and insight on your travel persona. Our service uses algorithms—including AI assistance and team-designed scoring models—to determine your travel profile and suggest destinations. The results are intended solely for informational and entertainment purposes and do not constitute professional travel advice.
      </p>

      <h2>4. User Accounts</h2>
      <h3>(A) Account Creation and Security</h3>
      <p>
        To access certain features, you may need to create an account (“Account”). You agree to provide accurate, current, and complete information during registration and to update such information as needed. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your Account.
      </p>
      <h3>(B) Account Termination</h3>
      <p>
        We reserve the right to suspend or terminate your Account if we suspect unauthorized use, fraudulent activity, or if you violate these Terms. Inactivity for extended periods may also result in Account closure.
      </p>

      <h2>5. License to Use the Service</h2>
      <p>
        Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to access and use our Services for personal, non-commercial purposes. You may not modify, reproduce, distribute, or create derivative works based on any part of the Services without our prior written consent.
      </p>

      <h2>6. AI-Assisted Test Processing</h2>
      <p>
        Our test results are generated through an automated process that includes AI-assisted analysis and predefined scoring models developed by our team. Although the results are automatically generated, we periodically review the outputs to ensure accuracy and fairness. This process is used solely for providing personalized travel recommendations and does not result in any legal, financial, or binding obligations. By using the Services, you acknowledge and consent to this automated processing of your responses.
      </p>

      <h2>7. Fees, Subscriptions, and Payment</h2>
      <h3>(A) Fees and Payment Terms</h3>
      <p>
        Some features of the Services may require payment (“Fees”). You agree to pay all applicable Fees as displayed during the checkout process. All payments are processed through our approved payment partners, and you must provide accurate billing information. Fees may be charged in your local currency or U.S. dollars; any conversion fees are your responsibility.
      </p>
      <h3>(B) Subscriptions and Automatic Renewal</h3>
      <p>
        For subscription-based Services, you authorize us to charge your payment method on a recurring basis until you cancel. Subscription terms, billing cycles, and renewal dates will be clearly communicated during the sign-up process. You may cancel your subscription at any time, but cancellation requests must be submitted at least one day prior to the next billing period to avoid additional charges.
      </p>

      <h2>8. User-Generated Content</h2>
      <p>
        If you submit content such as reviews, comments, or feedback (“User Content”), you grant us a worldwide, royalty-free, perpetual license to use, reproduce, modify, publish, and distribute such content in connection with the Services. You represent that you have the rights to submit this content and that it does not infringe on any third-party rights. We reserve the right to remove any User Content that violates these Terms or is deemed inappropriate at our sole discretion.
      </p>

      <h2>9. Acceptable Use</h2>
      <p>
        You agree not to misuse our Services. Prohibited actions include, but are not limited to:
      </p>
      <ul>
        <li>Providing false or misleading information</li>
        <li>Interfering with the functionality or security of the Services</li>
        <li>Attempting to reverse-engineer or extract the underlying algorithms, including those used for AI assistance</li>
        <li>Using the Services for unauthorized commercial purposes</li>
        <li>Collecting or harvesting personal information of other users</li>
      </ul>
      <p>
        Violations may result in suspension or termination of your Account.
      </p>

      <h2>10. Intellectual Property Rights</h2>
      <p>
        All content, trademarks, logos, and technology used in our Services, including our test, scoring algorithms, and AI-assisted processes, are the property of SoulCompass or its licensors. You are granted a limited license to use these materials solely for your personal use in connection with the Services. You agree not to copy, distribute, or exploit any content without prior written consent.
      </p>

      <h2>11. Dispute Resolution</h2>
      <h3>(A) Informal Resolution</h3>
      <p>
        If you have any concerns or disputes regarding our Services, please contact our support team at <a href="mailto:contact@SoulCompass.net">contact@SoulCompass.net</a>. We encourage you to first attempt to resolve any issues informally.
      </p>
      <h3>(B) Governing Law and Arbitration</h3>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of New York, United States of America, without regard to its conflict of law principles. Any disputes arising out of these Terms shall be resolved through binding arbitration in accordance with the United States of America Federal Arbitration Act and federal arbitration law. You agree that any dispute will be resolved on an individual basis, and you waive the right to participate in any class action or representative proceeding.
      </p>
      <h3>(C) Waiver of Class Actions</h3>
      <p>
        By using the Services, you agree that any legal claims you have against us will be resolved on an individual basis only, and you waive any right to join as a member of a class or representative action.
      </p>

      <h2>12. Modifications to the Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. We will notify you of any material changes by updating the “Effective Date” at the top of these Terms and by posting a notice on our website. Continued use of the Services after changes have been made constitutes your acceptance of the revised Terms. If you do not agree to the new Terms, you must cease using the Services.
      </p>

      <h2>13. Third-Party Links and Services</h2>
      <p>
        Our Services may include links to third-party websites, services, or content that are not controlled by us. We are not responsible for the content, privacy policies, or practices of these third parties. Your use of any third-party websites is at your own risk, and such use is governed solely by the terms and policies of the third party.
      </p>

      <h2>14. Term and Termination</h2>
      <p>
        These Terms are effective as of the date you begin using our Services and will remain in effect until terminated by either party. We may terminate or suspend your access to the Services at any time, with or without notice, for any reason, including if you breach these Terms. Upon termination, all rights granted to you will immediately cease, and you must stop using the Services. Termination does not affect any obligations or liabilities incurred prior to termination.
      </p>

      <h2>15. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless SoulCompass and its affiliates, directors, officers, employees, and agents from any claims, damages, liabilities, and expenses (including reasonable attorneys’ fees) arising out of or related to your use of the Services, your violation of these Terms, or your infringement of any third-party rights.
      </p>

      <h2>16. Limitation of Liability and Disclaimers</h2>
      <h3>(A) Assumption of Risk</h3>
      <p>
        You acknowledge that you use the Services at your own risk. The information provided through our test and travel recommendations is for entertainment purposes only and should not be considered professional advice.
      </p>
      <h3>(B) Limitation of Liability</h3>
      <p>
        To the fullest extent permitted by law, SoulCompass shall not be liable for any indirect, incidental, consequential, or punitive damages arising from your use of the Services. Our total liability to you for any claim shall not exceed the amount you paid to us in the 30 days preceding the claim.
      </p>
      <h3>(C) Disclaimer of Warranties</h3>
      <p>
        The Services are provided “as is” and “as available” without any warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that the Services will be uninterrupted, secure, or error-free.
      </p>

      <h2>17. Mobile Application License</h2>
      <p>
        If you access our Services via a mobile application, you are granted a non-exclusive, non-transferable, revocable license to use the app on your personal device strictly in accordance with these Terms and any additional guidelines provided in the app store. You agree not to reverse-engineer, decompile, or otherwise attempt to extract the source code of the mobile application.
      </p>

      <h2>18. Miscellaneous</h2>
      <p>
        These Terms constitute the entire agreement between you and SoulCompass regarding your use of the Services. If any provision of these Terms is found to be unenforceable, the remaining provisions will continue to be valid and enforceable. No waiver of any term or condition of these Terms shall be deemed a further or continuing waiver of such term or condition.
      </p>
      <p>
        Any notices or communications under these Terms must be sent via email to <a href="mailto:contact@SoulCompass.net">contact@SoulCompass.net</a> or by mail to the address listed above.
      </p>

      <h2>19. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms or our Services, please contact us at:
      </p>
      <p><strong>SoulCompass</strong></p>
      <p>
        Email: <a href="mailto:contact@SoulCompass.net">contact@SoulCompass.net</a>
      </p>
      <p className="last-updated">Last updated: February 24, 2025</p>
      <p className="copyright">
        © 2025 SoulCompass.net™. All rights reserved
      </p>
    </div>
  );
};

export default TermsAndConditions;
