import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./Share.css";
import { FaTwitter, FaFacebookF, FaWhatsapp, FaShare } from "react-icons/fa";

const Share = () => {
  const [searchParams] = useSearchParams();
  const refName = searchParams.get("ref");

  const displayName = refName
    ? `${refName.charAt(0).toUpperCase()}${refName.slice(1)}`
    : "you";

  const shareUrl = `https://soulcompass.net/invite?ref=${refName || "a-friend"}`;

  const handleNativeShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "SoulCompass – Travel that reflects your soul",
        text: `${displayName} invites you to discover your unique travel persona.`,
        url: shareUrl,
      });
    }
  };

  return (
    <div className="share-page">
      <div className="share-content">
        <h1>Pass the Compass Forward</h1>

        <p className="share-description compact">
          You’ve just uncovered something meaningful — now it's someone else's turn.<br />
          Share the link below with someone who should discover theirs too.
        </p>

        <div className="share-link-block">
          <code className="share-link">{shareUrl}</code>

          <div className="platform-buttons">
            <a
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                `${displayName} invites you to discover your unique travel persona with SoulCompass!`
              )}&url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn twitter"
              title="Share on X (Twitter)"
            >
              <FaTwitter />
            </a>

            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn facebook"
              title="Share on Facebook"
            >
              <FaFacebookF />
            </a>

            <a
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                `${displayName} invites you to discover your unique travel persona with SoulCompass! ${shareUrl}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn whatsapp"
              title="Share on WhatsApp"
            >
              <FaWhatsapp />
            </a>

            {navigator.share && (
              <button
                className="social-btn device-share"
                onClick={handleNativeShare}
                title="Share via Device"
                aria-label="Share via device"
              >
                <FaShare />
              </button>
            )}
          </div>
        </div>

        <div className="share-footer">
          This link leads to a personalized invite page with your name.
        </div>
      </div>
    </div>
  );
};

export default Share;