export const questions = [
  // Page 1: Who You Are When You Travel
  "I actively seek new experiences and adventures when traveling.", // Q1
  "Meeting new people and making friends is a highlight of my travels.", // Q2
  "Trying local foods is essential to my travel experience.", // Q3
  "I find travel more fulfilling when shared with others, rather than experienced alone.", // Q4
  "I feel more drawn to calming places near water (like oceans or rivers) than to hot, sun-filled landscapes like deserts or rocky cliffs.", // Q5

  // Page 2: Your Travel Preferences in Action
  "I love physically intense activities like hiking or diving.", // Q6
  "I am willing to spend more for high-quality accommodations and dining.", // Q7
  "Finding the best value for money is crucial to me.", // Q8
  "Discovering new places excites me more than returning to familiar ones.", // Q9
  "I prefer slow, peaceful travel experiences over high-energy or adrenaline-filled ones.", // Q10

  // Page 3: Curiosity & Cultural Engagement
  "Cultural and historical explorations are my preferred travel activities.", // Q11
  "I enjoy visiting sacred sites, temples, or religious landmarks when I travel.", // Q12
  "I value visiting museums and attending local performances when traveling.", // Q13
  "Learning local languages enhances my travel experiences.", // Q14
  "When I travel, I’m more drawn to healing and reflection than intensity and personal transformation.", // Q15

  // Page 4: Structure, Flexibility, and Risk
  "I plan my trips well in advance to ensure everything is arranged.", // Q16
  "I enjoy making spontaneous travel decisions rather than following a structured itinerary.", // Q17
  "I feel anxious in unfamiliar situations.", // Q18
  "I adapt quickly to new cultures and environments.", // Q19
  "I’m drawn to activities that carry physical risk, like skydiving or white-water rafting.", // Q20

  // Page 5: Your Deeper Travel Personality
  "I seek travel experiences that challenge me emotionally, foster growth, or deeply immerse me in new cultures.", // Q21
  "I often open up to strangers I meet while traveling.", // Q22
  "I’m comfortable traveling to places with unpredictable conditions and limited infrastructure.", // Q23
  "I feel more drawn to grounded landscapes like forests and mountains than to open, elevated places like wide fields or cliff edges.", // Q24
  "When I travel, I’m more drawn to feeling rooted and connected than to feeling free, detached, or in motion.", // Q25

  // Page 6: The Values That Guide Your Travel
  "I’m willing to adjust my travel plans to reduce environmental impact.", // Q26
  "I prefer public transportation while traveling because of its lower environmental impact.", // Q27
  "I feel more creatively inspired in lively cities than in quiet, natural settings.", // Q28
  "I tend to avoid destinations that feel chaotic, overly noisy, or packed with people.", // Q29
  "I prefer local experiences that reflect real daily life, even if they’re less polished, over activities designed for tourists.", // Q30

  // Page 7: The Little Things That Matter
  "Luxury accommodations are important to me.", // Q31
  "Traveling solo is more appealing to me than traveling with others.", // Q32
  "I usually travel during the summer months.", // Q33
  "I feel more energized to explore in the morning than in the evening.", // Q34
  "Staying connected online is important to me when I travel." // Q35
];

export const ratingOptions = [
  { label: "Strongly Disagree", color: "#EC8A8A", fill: "#F9DCDC" },
  { label: "Disagree", color: "#F6C48B", fill: "#FCE6CF" },
  { label: "Neutral", color: "#D3D3D3", fill: "#EAEAEA" },
  { label: "Agree", color: "#B0E3A2", fill: "#DFF4D8" },
  { label: "Strongly Agree", color: "#7FD1AE", fill: "#C5EBD6" },
];

export const questionsPerPage = 5;  