// src/config/apiConfig.js

const isProduction = process.env.NODE_ENV === "production";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  (isProduction
    ? "https://soulcompass.net/api"
    : "http://localhost:5000/api");

export default API_BASE_URL;
