import React, { useEffect, useState } from 'react';
import './Success.css';
import API_BASE_URL from "../config/apiConfig";
import SuccessImage from '../components/Success.png';

const Success = () => {
  const [personalityShortDescription, setPersonalityShortDescription] = useState("Loading...");
  const personalityImage = SuccessImage;

  useEffect(() => {
    const token = localStorage.getItem("downloadToken");

    if (!token) {
      setPersonalityShortDescription("Unknown traveler");
      return;
    }

    fetch(`${API_BASE_URL}/personality-summary/${token}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.personalityShortDescription) {
          setPersonalityShortDescription(data.personalityShortDescription);
        } else {
          setPersonalityShortDescription("Traveler");
        }
      })
      .catch((err) => {
        console.error("Failed to load personality summary", err);
        setPersonalityShortDescription("Traveler");
      });
  }, []);

  const handleReviewClick = (liked) => {
    if (liked) {
      window.location.href = "https://www.trustpilot.com/evaluate/yourwebsite.com";
    } else {
      alert("Thank you for your feedback!");
    }
  };

  const handleDownloadReport = () => {
    const token = localStorage.getItem("downloadToken");
    if (!token) {
      alert("Download token missing. Please check your email or refresh.");
      return;
    }
    window.open(`${API_BASE_URL}/secure-download/${token}`, "_blank");
  };

  return (
    <div className="success-container">
      <h1>Thank you for your purchase!</h1>
      <h2>Your travel personality is: {personalityShortDescription}</h2>
      <img 
        src={personalityImage} 
        alt={personalityShortDescription} 
        className="personality-image" 
      />

      <div className="feedback-container">
        <p>Did you like the travel personality and your results?</p>
        <div className="feedback-buttons">
          <button className="yes-button" onClick={() => handleReviewClick(true)}>
            Yes
          </button>
          <button className="no-button" onClick={() => handleReviewClick(false)}>
            No
          </button>
        </div>
      </div>

      <p className="download-info">
        Click 'Download my Report' button below to access your report!
      </p>

      <div className="download-container">
        <button className="download-button" onClick={handleDownloadReport}>
          Download my Report
        </button>
      </div>
    </div>
  );
};

export default Success;
