import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { questions, ratingOptions, questionsPerPage } from "../Data/testData";
import "./Test.css";
import { jwtDecode } from "jwt-decode";
import API_BASE_URL from "../config/apiConfig";

const Test = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(() => {
    const savedState = sessionStorage.getItem('testProgress');
    return savedState ? JSON.parse(savedState).savedPage : 0;
  });
  const [answers, setAnswers] = useState(() => {
    const savedState = sessionStorage.getItem('testProgress');
    return savedState ? JSON.parse(savedState).savedAnswers : Array(questions.length).fill(null);
  });
  const [error, setError] = useState(false);

  // Session storage for progress persistence
  useEffect(() => {
    const savedState = sessionStorage.getItem('testProgress');
    if (savedState) {
      const { savedPage, savedAnswers } = JSON.parse(savedState);
      setCurrentPage(savedPage);
      setAnswers(savedAnswers);
    }
  }, []);

  // Save progress on change
  useEffect(() => {
    sessionStorage.setItem('testProgress', JSON.stringify({
      savedPage: currentPage,
      savedAnswers: answers
    }));
  }, [currentPage, answers]);

  // Progress calculation with color transitions
  const calculateProgress = () => {
    const answered = answers.filter(a => a !== null).length;
    const progress = (answered / questions.length) * 100;
    
    let color;
    if (progress <= 33) color = '#ff6b6b';
    else if (progress <= 66) color = '#ffd166';
    else color = '#76c7c0';
    
    return { percentage: progress, color };
  };

  const handleAnswer = (questionIndex, value) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = newAnswers[questionIndex] === value ? null : value;
    setAnswers(newAnswers);
    setError(false);

    // Auto-scroll to next question after 100ms
    setTimeout(() => {
      const nextQuestionIndex = questionIndex + 1;
      if (nextQuestionIndex < questions.length) {
        const nextQuestionElement = document.getElementById(`question-${nextQuestionIndex}`);
        if (nextQuestionElement) {
          nextQuestionElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    }, 100);
  };

  const handleNext = () => {
    const start = currentPage * questionsPerPage;
    const end = start + questionsPerPage;
    const currentAnswers = answers.slice(start, end);

    if (currentAnswers.some(a => a === null)) {
      setError(true);
      return;
    }

    setError(false);
    if (currentPage < totalPages - 1) {
      setCurrentPage(prev => prev + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // NEW: Instead of submitting to backend here, we simply navigate to the complete page.
  const handleGoToComplete = () => {
    const start = currentPage * questionsPerPage;
    const end = start + questionsPerPage;
    const currentAnswers = answers.slice(start, end);

    if (currentAnswers.some(a => a === null)) {
      setError(true);
      return;
    }

    setError(false);
    navigate("/test/complete");
  };

  // Progress data
  const { percentage, color } = calculateProgress();
  const totalPages = Math.ceil(questions.length / questionsPerPage);

  return (
    <div className="test-wrapper">
      <div className="test-container">
        <div className="progress-header">
          <div className="progress-text">
            <span className="progress-value">{Math.round(percentage)}</span>
            <span>%</span>
          </div>
          <div className="progress-text">
            <span className="progress-value">Step {currentPage + 1} of {totalPages}</span>
          </div>
        </div>

        <div className="progress-bar">
          <div 
            className="progress" 
            style={{ 
              width: `${percentage}%`,
              backgroundColor: color,
              transition: 'all 0.3s ease'
            }}
          ></div>
        </div>

        <div className="previous-button-container">
          {currentPage > 0 && (
            <button className="previous-button" onClick={handlePrev}>
              <svg viewBox="0 0 24 24" role="presentation" style={{ width: '1.5rem', height: '1.5rem' }}>
                <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" style={{ fill: 'rgba(0, 13, 77, 0.45)' }}></path>
              </svg>
            </button>
          )}
        </div>

        <h2 className="test-title">Choose how accurately each statement reflects you.</h2>

        <div className="rating-scale">
          {ratingOptions.map((option, i) => (
            <div key={i} className="rating-option">
              <div 
                className="circle" 
                style={{ 
                  borderColor: option.color, 
                  backgroundColor: option.fill 
                }}
              ></div>
              <span className="rating-label">{option.label}</span>
            </div>
          ))}
        </div>

        <div className="question-list">
          {questions
            .slice(
              currentPage * questionsPerPage,
              (currentPage + 1) * questionsPerPage
            )
            .map((question, index) => {
              const questionIndex = currentPage * questionsPerPage + index;
              
              return (
                <div 
                  key={questionIndex} 
                  className="question-item"
                  id={`question-${questionIndex}`}
                >
                  <p className="question-text">{question}</p>
                  <div className="options">
                    {ratingOptions.map((option, i) => (
                      <div
                        key={i}
                        className={`circle selectable ${answers[questionIndex] === i + 1? "selected" : ""}`}
                        style={{
                          borderColor: option.color,
                          backgroundColor: answers[questionIndex] === i + 1
                            ? option.color 
                            : option.fill,
                        }}
                        onClick={() => handleAnswer(questionIndex, i + 1 )}
                      >
                        {answers[questionIndex] === i + 1 && (
                          <svg 
                            viewBox="0 0 24 24" 
                            className="check"
                            style={{ width: '1.5rem', height: '1.5rem' }}
                          >
                            <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                          </svg>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>

        {error && <p className="error-message">All questions must be answered before you continue.</p>}

        <div className="navigation-buttons">
          {currentPage < totalPages - 1 ? (
            <button className="btn btn-primary" onClick={handleNext}>
              Next
            </button>
          ) : (
            // Changed: Now simply navigate to complete page.
            <button className="btn btn-success" onClick={handleGoToComplete}>
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Test;
