import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faHeadset, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import "./Pricing.css";

const Pricing = () => {
  const navigate = useNavigate(); // Hook for navigation

  // Function to handle button click and navigate to the Test component
  const handleDiscovery = () => {
    navigate("/test/start"); // Navigate to the Test component
  };

  return (
    <div className="pricing-page">
      {/* Top Section */}
      <div className="pricing-top-section">
        <h1>Unlock Your Personality's Potential with SoulCompass</h1>
        <p>
          Discover the true essence of who you are with SoulCompass. Through our advanced personality insights, 
          we provide tailored travel plans designed to align with your unique traits and aspirations. 
          With a one-time payment, gain access to a comprehensive analysis that helps you create travel experiences 
          that perfectly reflect your individuality.
        </p>
      </div>

      {/* Free and Premium Insights Section */}
      <div className="pricing-section">
        <h2 className="pricing-title-page">Choose Your Path to Self-Discovery</h2>
        <div className="pricing-options">
          {/* Free Insights Section */}
          <div className="pricing-card">
            <div className="pricing-icon">
              <i className="fas fa-eye"></i>
            </div>
            <h3>Free Personality Insights</h3>
            <p>
              Begin your journey of self-discovery with SoulCompass. Learn key aspects of your personality and gain a glimpse into how it shapes your travel preferences.
            </p>
            <ul>
              <li>High-level overview of your personality traits</li>
              <li>Insight into your top strengths and focus areas</li>
              <li>Easy access and no payment required</li>
            </ul>
            <button className="btn-free" onClick={handleDiscovery}>Start Your Discovery</button>
          </div>

          {/* Full Report Section */}
          <div className="pricing-card premium">
            <div className="pricing-card-inner">
              <div className="pricing-icon">
                <i className="fas fa-file-alt"></i>
              </div>
              <h3>Full Personality Report + Tailored Plans</h3>
              <p>
                Dive deeper into your unique personality with SoulCompass's detailed report. Unlock a wealth of insights and receive personalized plans crafted to suit your goals and preferences.
              </p>
              <ul>
                <li>Comprehensive analysis of your personality type</li>
                <li>Customized plans based on your traits</li>
                <li>Actionable recommendations to achieve your aspirations</li>
              </ul>
              <div className="price">$2.95</div>
              <button className="btn-premium" onClick={handleDiscovery}>Start Your Discovery</button>
            </div>
          </div>
        </div>
      </div>

      {/* Why Us Section */}
      <div className="why-us-section">
        <p className="why-us-title">Why Choose SoulCompass?</p>
        <h2>Why Embark on This Journey With Us?</h2>
        <div className="why-us-cards">
          <div className="why-us-card">
            <div className="icon-circle-pricing">
              <FontAwesomeIcon icon={faThumbsUp} />
            </div>
            <h3>Fair Pricing</h3>
            <p>
              Our straightforward pricing ensures you know exactly what you're getting—no subscriptions, no hidden costs, no surprises.
            </p>
          </div>
          <div className="why-us-card">
            <div className="icon-circle-pricing">
              <FontAwesomeIcon icon={faClipboardList} />
            </div>
            <h3>Tailored to You</h3>
            <p>
              Receive a detailed, one-of-a-kind report crafted to reflect your unique personality and precision-tailored travel recommendations.
            </p>
          </div>
          <div className="why-us-card">
            <div className="icon-circle-pricing">
              <FontAwesomeIcon icon={faHeadset} />
            </div>
            <h3>Dedicated Support</h3>
            <p>
              From exploring your personalized report to applying insights to your life, SoulCompass support is here to guide you every step of the way.
            </p>
          </div>
        </div>
      </div>

      {/* Ready to Unlock Your Potential Section */}
      <div className="unlock-potential-section">
        <div className="unlock-potential-image">
          <img src="/images/unlock_potential.png" alt="Unlock Potential" />
        </div>
        <div className="unlock-potential-content">
          <h2>Ready to Unlock Your Perfect Plan?</h2>
          <ul className="unlock-potential-list">
            <li>
              <span>
                <strong>Begin Today:</strong> Start your personalized journey now with SoulCompass insights tailored to your personality.
              </span>
            </li>
            <li>
              <span>
                <strong>Freedom to Explore:</strong> Dive into actionable insights designed to transform your experiences.
              </span>
            </li>
          </ul>
        </div>
      </div>

      {/* Questions or Curiosities Section */}
      <div className="questions-section">
        <div className="questions-image">
          <img src="/images/questions_support.png" alt="Questions or Curiosities" />
        </div>
        <div className="questions-content">
          <h2>Questions or Curiosities?</h2>
          <p>
            Have questions about SoulCompass or looking for additional information? Our dedicated support team is here to assist you at every step.
          </p>
          <p>
            Reach out, and let us help you make the most of your journey!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
